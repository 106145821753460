<template>
<main class="d-flex align-items-center container pt-0 mt-0" id="servicebilling">
  <div v-if="emmplatform" class="container">
    <p class="text-center"><strong>Exact Match Marketing White Label Platform<br />&nbsp;Services Agreement</strong></p>
                <p><br/></p>
                <p class="text-center"><strong><u>IMPORTANT &ndash; READ CAREFULLY</u></strong></p>
                <p><strong>THIS SERVICES AGREEMENT (ALONG WITH THE ORDER FORM, THE TERMS OF USE, AND THE PRIVACY POLICY, COLLECTIVELY THIS &ldquo;AGREEMENT&rdquo;), IS BY AND BETWEEN EXACT MATCH MARKETING INC., AN OHIO COMPANY (&ldquo;EXACT MATCH&rdquo;) AND THE ENTITY OR INDIVIDUAL ON WHOSE BEHALF THE SERVICES (AS DEFINED BELOW) ARE BEING ACCESSED OR USED (&ldquo;CUSTOMER&rdquo;). EACH MAY BE REFERRED TO HEREIN COLLECTIVELY AS THE &ldquo;PARTIES&rdquo; OR EACH INDIVIDUALLY AS A &ldquo;PARTY.&rdquo;&nbsp;</strong></p>
                <p><br/></p>
                <p><strong>BY INDICATING ACCEPTANCE OF THIS AGREEMENT, EITHER BY CLICKING ON THE &ldquo;AGREE&rdquo; BUTTON, DURING THE ACCOUNT SETUP PROCESS OR USING THE SERVICES, (&ldquo;EFFECTIVE DATE&rdquo;) YOU ARE AGREEING THAT CUSTOMER IS BOUND BY AND USE OF THE SERVICES ON BEHALF OF THE CUSTOMER IS GOVERNED BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF CUSTOMER DOES NOT ACCEPT THIS AGREEMENT, CUSTOMER MAY NOT ACCESS OR USE THE SERVICES.</strong></p>
                <p><br/></p>
                <p><strong>RECITALS </strong></p>
                <p><br/></p>
                <p>WHEREAS, Exact Match has developed a proprietary software offering services to generate leads and disseminate and track advertising and marketing materials that can be used to grow specific needs of a business, and</p>
                <p><br/></p>
                <p>WHEREAS, Customer wishes to subscribe to and license Exact Match&rsquo;s software as a white label Platform to authorized users pursuant to this Agreement, and&nbsp;</p>
                <p><br/></p>
                <p>NOW, THEREFORE, acknowledging the receipt of adequate consideration and intending to be legally bound, the Parties agree as follows:</p>
                 <p><br/></p>
                <p class="bold">1. Services.</p>
                <p><br/></p>
                <p><strong>1.1. </strong>&nbsp;&ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted by the Parties.&nbsp;</p>
                <p><br/></p>
                <p><strong>1.2. </strong>&ldquo;Platform&rdquo; means&nbsp;the proprietary software-as-a-service managed by Exact Match, in both source code and object code form, components, documentations, implementation, integration and improvements thereof, provided to Customer&nbsp;as described in the Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to Customer or any Authorized User (as defined below) in connection with the foregoing.</p>
                <p><br/></p>
                <p><strong>1.3. </strong>&ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including Exact Match&rsquo;s subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that Exact Match provides to Customer through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
                <p><br/></p>
                <p><strong>1.4. </strong>Customer shall be entitled to a trial period of sixty (60) days commencing on the Effective Date at no charge (the &ldquo;Free Trial Period&rdquo;). At any time prior to expiration of the Free Trial Period, Customer may elect to cancel this Agreement by providing written notice to Exact Match (&ldquo;Trial Cancellation Notice&rdquo;). If Customers does not provide the Trial Cancellation Notice, then Customer agrees to the terms the Agreement and to pay all applicable charges according to the Agreement.</p>
                <p><br/></p>
                <p><strong>1.5. </strong>The Services are available only to Authorized Users. &ldquo;Authorized User&rdquo; means anyone, including end users, who Customer gives permission to access and use the Platform in accordance with this Agreement.&nbsp;</p>
                <p><br/></p>
                <p><strong>1.6. </strong>Customer may access the Services only to the extent authorized by Exact Match. Customer is responsible for use of Services by its Authorized Users and other users, whether or not authorized, that access or use the Services using the accounts or credentials of Customer or any Authorized User, and Customer will be liable for any action or omission of any of the preceding that would violate this agreement as if such action or omission were taken by Customer itself. Customer shall ensure that Authorized Users comply with Exact Match&rsquo;s Terms of Use, found at&nbsp;https://exactmatchmarketing.com/index.php/terms-of-use/, (the &ldquo;Terms of Use&rdquo;) which are incorporated herein by reference, when using the Services. Customer acknowledges that it has read and agrees to be bound by the Terms of Use and will properly describe to Authorized Users all capabilities and limitations of the Platform. Customer is responsible for Customer&rsquo;s and its Authorized User&rsquo;s actions and the contents of its transmissions through the Services. These Authorized Users, whether located in or outside of the U.S., and their usage of the Platform are Customer&rsquo;s sole responsibility, and Customer must comply with all applicable laws and security protocols pertaining to their access, including applicable export laws, restrictions, and regulations. For the avoidance of doubt, the Platform should not be made available or copied/distributed to prohibited countries, persons or entities, as indicated in an Office of Foreign Assets Control (OFAC) of the US Department of the Treasury list.</p>
                <p><br/></p>
                <p><strong>1.7. </strong>Customer may not, and will ensure that its Authorized User&rsquo;s do not: (a) duplicate, disassemble, decompile, reverse engineer, or otherwise reproduce without authorization any portion of the Services or source code for the Platform; (b) resell, sublicense or allow any third party (other than Authorized Users, for whom Customer shall be wholly responsible) to use the Services, except pursuant to the normal operation of the Platform; (c) scrape, steal, or copy without authorization the Services; (d) disclose any performance data relating to the Services; (e) sell or transfer to another third party the Services in violation of this Agreement; (f) build a product, service, or offering that competes with Exact Match or Services; (g) use the name or proprietary logo(s) of Exact Match without Exact Match&rsquo;s prior written consent; (h) use the Services for any purpose other than the operation of the Platform; or (i) use the Services in a manner that interferes with the use of the Platform by Exact Match, its other customers.</p>
                <p><br/></p>
                <p><strong>1.8. </strong>Customer may not and will ensure that its Authorized User’s do not: use the Services for: (a) defamatory, harassing, abusive, threatening, obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that violates or infringes upon the rights of third parties; (c) activity that violates applicable law; (d) sending viruses or spyware or similar computer programming routines; or (e) any purposes inconsistent with this Agreement or which violate the Terms of Use and Privacy Policy.</p>
                <p><br/></p>
                <p class="bold">2. Customer Responsibilities.</p>
                <p><br/></p>
                <p><strong>2.1. </strong>This Agreement and Services are a valuable trade secret and confidential proprietary property of Exact Match. Customer agrees to access and use the Services only as provided in this Agreement and to safeguard Exact Match&rsquo;s trade secrets and confidential proprietary property. Customer agrees to delete the Materials upon termination of this Agreement, unless mutually agreed otherwise in writing.</p>
                <p><br/></p>
                <p><strong>2.2. </strong>Customer is hereby provided with limited access to the Services subject to this Agreement. Subject to the terms of this Agreement, Exact Match hereby provides to Customer a limited, non-exclusive, non-transferrable, revocable-at-any-time license during the term stated in the applicable Order Form to access and use the Materials for its ordinary business purposes, (the &ldquo;License&rdquo;). Except as otherwise stated in this section or on the Order Form, Customer does not obtain any other rights to the Services or Materials. Customer&rsquo;s License to use the Materials is only valid for six (6) months from purchase (or less if required by Exact Match data providers, as set forth in more detail in the Order Form).&nbsp;</p>
                <p><br/></p>
                <p><strong>2.3. </strong>Customer shall: (i) not use for solicitation the name, mailing address or telephone number of a consumer that is designated within the Service as requesting protection from solicitation, (ii) abide by all prevailing federal, state, and local laws and regulations governing fair information practices and consumers&rsquo; rights to privacy, and (iii) limit access to consumer information to those individuals who have a &ldquo;need to know&rdquo; in connection with Customer&rsquo;s business and will obligate those individuals to acknowledge consumers&rsquo; rights to privacy and adhere to fair information practices and consumer&rsquo;s right to privacy.</p>
                <p><br/></p>
                <p><strong>2.4. </strong>&ldquo;Customer Content&rdquo; means all content, information, or data that Customer provides or authorizes access to for the Services. Except as otherwise provided in the Order Form, Customer hereby grants Exact Match a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process de-identified Customer Content to provide or improve Exact Match&rsquo;s Services or for its business purposes. Customer warrants that it has the right and authority to provide Customer Content and that such materials do not infringe the rights of others or violate applicable law.</p>
                <p><br/></p>
                <p><strong>2.5. </strong>Customer is responsible for obtaining all necessary rights and permissions to enable, and grants such rights and permissions to, Exact Match, its affiliates, and their respective contractors and vendors to use, provide, store and otherwise process Customer Content in the Services. This includes Customer making necessary disclosures and obtaining consent, if required, before providing individuals&rsquo; information to Exact Match.</p>
                <p><br/></p>
                <p><strong>2.6. </strong>Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s intended use; (b) taking necessary actions to order, enable, or use available features appropriate for its use of the Services; (c) complying with applicable law; and (d) the compliance of Customer Content with this Agreement, including content uploaded by its Authorized Users.</p>
                <p><br/></p>
                <!-- <p><strong>2.7 </strong>Use of the Platform by Customer&rsquo;s Authorized Users is also subject to Platform Terms of Use and Platform Privacy Policy between Customer and Authorized Users, and Customer agrees that such Platform Terms of Use and Platform Privacy Policy will: (i) be included as at least the minimum end-user terms; and (ii) will be accepted by each Authorized User prior to their use of the Platform. Customer shall ensure that all Authorized Users are aware of: (a) this Agreement&rsquo;s provisions as applicable to the Authorized Users of the Platform; and (b) the Platform Terms of Use and the Platform Privacy Policy and their applicability to the Authorized Users of the Platform, and shall ensure that the Authorized Users comply with such provisions of this Agreement and the Terms of Use and Privacy Policy.</p>
                <p><br/></p> -->
                <p><strong>2.7. </strong>Customer shall cause each of its Authorized users to enter into an end-user agreement that, at a  minimum, provides that:</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.1.	The Platform is only licensed to an end user for the end user’s non-transferable and non-exclusive use, without the right to sublicense;</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.2.	no right, title, or interest to the Platform, and any intellectual property rights in the Platform, is transferred to the end user;</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.3.	except to the limited extent as may be permitted under applicable law, the end user agrees not to modify, translate, generate derivative works from or reverse assemble, decompile, or otherwise attempt to derive source code from the Platform;</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.4.	the Platform is subject to Company’s copyrights and Company owns all intellectual property rights in the Platform. In some instances although copyrighted, are unpublished and contain proprietary and confidential information of Company and are considered by Company to be trade secrets and the end user agrees to hold the Platform in confidence and the end user further agrees to take all reasonable precautions to safeguard the confidentiality of such Platform.  The end user further agrees not to use the Platform to develop a competitive product or for any other purpose, except for its own internal business needs;</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.5.	The end user’s rights with respect to the Platform may be terminated should the end user breach any terms of the end user agreement and fail to cure such breach within thirty (30) days after written notice;</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.6.	Company is an expressly intended third-party beneficiary of the end user agreement and shall have the right to enforce its rights directly against the end user; and</p>
                <p><br/></p>
                <p style="margin-left: 1rem;">2.7.7.	Use of the Platform by Customer’s Authorized Users is also subject to Platform Terms of Use and Platform Privacy Policy between Customer and Authorized Users, and Customer agrees that such Platform Terms of Use and Platform Privacy Policy will: (i) be included as at least the minimum end-user terms; and (ii) will be accepted by each Authorized User prior to their use of the Platform. Customer shall ensure that all Authorized Users are aware of: (a) this Agreement’s provisions as applicable to the Authorized Users of the Platform; and (b) the Platform Terms of Use and the Platform Privacy Policy and their applicability to the Authorized Users of the Platform, and shall ensure that the Authorized Users comply with such provisions of this Agreement and the Terms of Use and Privacy Policy.</p>
                <p><br/></p>
                <p class="bold">3. Charges, Taxes, and Payment.</p>
                <p><br/></p>
                <p><strong>3.1. </strong>Customer agrees to spend a minimum $500 per month during the term of this Agreement, (the &ldquo;Minimum Purchase Commitment&rdquo; or &ldquo;MPC&rdquo;), in accordance with the terms of this Agreement. If Customer fails to purchase a minimum $500 per month to meet the MPC in any given month, then Customer shall be invoiced according to the Agreement an amount equal to the shortfall.</p>
                <p><br/></p>
                <p><strong>3.2. </strong>Customer shall pay all applicable charges and fees specified for the Services&nbsp;under this Agreement as selected by Customer within the Platform for the selected Services and any charges imposed by any authority resulting from Customer's use of the Services, including any applicable sales taxes. The charges described in this Agreement and the Order Form are exclusive of any federal, state, municipal, or foreign taxes, levies, duties or other governmental assessments of any nature, including excise, sales, use, value-added, gross receipts, personal property, occupational, or other taxes (collectively, &ldquo;Taxes&rdquo;) in the United States or applicable countries. Customer is responsible for paying all Taxes associated with its purchases hereunder, excluding taxes based on Exact Match&rsquo;s income or property.</p>
                <p><br/></p>
                <p><strong>3.3. </strong>Customer agrees to set up automatic payments in amounts due as set forth in this Agreement and the Order Form through Exact Match&rsquo;s third-party payment processor. To do so, Customer shall provide a valid credit card number or establish ACH funds transfer prior to obtaining access to the Services. Customer shall ensure adequate funds are available to pay any amounts due hereunder.</p>
                <p><br/></p>
                <p><strong>3.4. </strong>All subscriptions are annual with monthly or annual billing payments. Except as otherwise stated in this Agreement, all subscription purchases are final and non-refundable. Annual subscription renewal cancellation requests are subject to the terms set forth in this Agreement.</p>
                <p><br/></p>
                <p><strong>3.5. </strong>Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not paid within 30 calendar days of the invoice date. Exact Match may, at its sole discretion, terminate this Agreement if Customer has failed to pay any amount due for more than 30 calendar days.</p>
                <p><br/></p>
                <p class="bold">4. Confidentiality.</p>
                <p><br/></p>
                <p><strong>4.1. </strong>&ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary information, formulae, processes, techniques, and information concerning past, present, and future marketing, financial, research, and development activities that may be disclosed, orally or in writing, to each other. Confidential Information excludes information that was (a) previously known to the receiving party without an obligation of confidence; (b) independently developed by or for the receiving party without the use of Confidential Information; (c) lawfully acquired by the receiving party from a third party which is not under an obligation of confidence with respect to such information; or (d) or becomes publicly available through no fault of the receiving party without a breach of this Agreement.</p>
                <p><br/></p>
                <p><strong>4.2. </strong>Customer shall maintain the privacy, security, and confidentiality of Confidential Information and its access to the Services. A temporary password will be given upon account creation. Customer agrees to immediately reset their initial password with a strong password. Customer shall use strong and secure passwords and keep them secure and confidential. Customer shall promptly notify Exact Match in the event of a security breach or unauthorized use of their account. Customer is responsible for any damages incurred as a result of the unauthorized use of their account.</p>
                <p><br/></p>
                <p><strong>4.3. </strong>Exact Match shall use reasonable efforts to maintain the privacy, security, and confidentiality of Confidential Information in accordance with this Agreement and its policies.</p>
                <p><br/></p>
                <p><strong>4.4. </strong>During the term of this Agreement and at all times thereafter in which Confidential Information remains confidential, neither Party shall disclose Confidential Information of the other Party or use such Confidential Information for any purpose other than in furtherance of this Agreement. Without limiting the preceding sentence, each party shall use at least the same degree of care in safeguarding the other Party&rsquo;s Confidential Information as it uses to safeguard its own Confidential Information. Notwithstanding the foregoing a Party may disclose Confidential Information (a) if required to do so by legal process (i.e., by a subpoena), provided that such Party shall notify the other Party prior to such disclosure so that such other Party may attempt to prevent such disclosure or seek a protective order; or (b) to any applicable governmental authority as required in the operation of such Party&rsquo;s business, and even then, limited to no more than the minimum information required.</p>
                <p><br/></p>
                <p class="bold">5. Ownership of Intellectual Property and Data.</p>
                <p><br/></p>
                <p><strong>5.1. </strong>Exact Match is the exclusive owner of the Platform and Services, and all of the intellectual property associated with its Platform and Services, including software, copyrights, patents, trademarks, trade secrets, and other data not owned by Customer, even if Exact Match incorporates suggestions made by Customer. Customer shall not receive any form of compensation for ideas, modifications, suggestions, or improvements made to Exact Match&rsquo;s Platform or Services. Except as expressly set forth in this Agreement, Customer is granted no license or other rights in or to any Exact Match data or any use thereof.&nbsp;</p>
                <p><br/></p>
                <p><strong>5.2. </strong>Customer shall own and retain all right, title and interest in and to any data, information, and other content, including any text, files, images, graphics, illustrations, information, data, audio, video, photographs, completed program performance and analytics provided by Customer or Customer&rsquo;s Authorized Users. (&ldquo;Customer Content&rdquo;).</p>
                <p><br/></p>
                <p><strong>5.3. </strong>Customer grants Exact Match all rights and licenses in and to the Customer Content as may be useful or reasonably necessary for Exact Match to provide the Services and perform its other obligations under this Agreement. Customer has all rights in and to all Customer Content necessary to permit Exact Match to provide the Services and perform its other obligations under this Agreement.&nbsp;</p>
                <p><br/></p>
                <p class="bold">6. White-Label Content and Branding.</p>
                <p><br/></p>
                <p><strong>6.1. </strong>&nbsp;&ldquo;White-Label Content&rdquo; means any Customer Content that Customer provides to the Platform, as new or replacement material for the purpose of white labeling Customer&rsquo;s instance of the Platform.</p>
                <p><br/></p>
                <p><strong>6.2. </strong>Platform branding includes incorporation of Customer&rsquo;s Data including Customer&rsquo;s name and logo and skinning of the site in Customer&rsquo;s selected primary and secondary colors. The standard Platform URL will appear with Customer&rsquo;s selected word as a subdomain of Exact Match (e.g. Name.exactmatchmarketing.com) unless Customer requests a custom URL. Custom or &lsquo;vanity&rsquo; URLs, which shall include provision of a Secure Socket Layer (SSL), are available upon request. Customer is responsible for clearing any privacy rights, copyright, trademark or other intellectual property concerns connected with a custom URL as well as securing the URL via a registrar service.</p>
                <p><br/></p>
                <p><strong>6.3. </strong>Customer agrees to collaborate with Exact Match on initial implementation of White-Label Content. Customer will be responsible for editing and maintaining the White-Label Content after this initial implementation. White-Label Content changes may include customizing setup categories, tasks, content, related resources, assessments, and other content changes that fit within the Platform&rsquo;s existing functions, as may be identified and changed by Exact Match from time to time and at Exact Match&rsquo;s sole discretion.</p>
                <p><br/></p>
                <p><strong>6.4. </strong>After the initial implementation, Customer shall ensure that no Authorized Users remove, delete, alter, or obscure any trademarks, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Platform without the prior written consent of Exact Match.&nbsp;A copyright notice with Exact Match&rsquo;s name and Terms of Use, provided at https://exactmatchmarketing.com/index.php/terms-of-use/, shall appear on the Platform.</p>
                <p><br/></p>
                <p><strong>6.5. </strong> Customer acknowledges that charged or invoiced fees as stated in the Agreement and Order Form may include additional costs related to support, training, and other services related to the Platform and the Services. Unless otherwise provided in an Order Form, such additional support, training, and other services will be invoiced to Customer on a time and materials basis, at Exact Match&rsquo;s then-standard hourly rates.</p>
                <p><br/></p>
                <p class="bold">7. Warranties.</p>
                <p><br/></p>
                <p>Each party warrants to the other that this Agreement has been duly executed and delivered and constitutes a valid and binding agreement enforceable against such party in accordance with its terms.</p>
                <p><br/></p>
                <p class="bold">8. Warranty Disclaimer.</p>
                <p><br/></p>
                <p>THE SERVICES PROVIDED ARE &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT APPLY. NEITHER EXACT MATCH MARKETING NOR ITS THIRD-PARTY PROVIDERS ASSURES OR ASSUMES ANY LIABILITY TO ANY PERSON OR ENTITY FOR THE PROPER PERFORMANCE OF SERVICES NECESSARY TO THE CONDUCT OF A REAL ESTATE CLOSING. NEITHER EXACT MATCH MARKETING NOR ITS THIRD-PARTY PROVIDER REPRESENT OR WARRANT (a) UNINTERRUPTED, TIMELY, OR ERROR-FREE SERVICES, (b) THAT EXACT MATCH MARKETING WILL CORRECT ANY DEFECTS OR PREVENT THIRD-PARTY DISRUPTIONS OR UNAUTHORIZED THIRD-PARTY ACCESS, OR (c) THAT SERVICES ARE SECURE, AVAILABLE, ACCURATE, PRIVATE, CONFIDENTIAL, APPROPRIATE, RELIABLE, OR COMPLETE. NEITHER EXACT MATCH MARKETING NOR ITS THIRD-PARTY PROVIDERS DO NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE, ACCIDENT, OR OTHER CAUSE.</p>
                <p><br/></p>
                <p class="bold">9. Indemnity.</p>
                <p><br/></p>
                <p><strong>9.1. </strong>Customer shall defend, indemnify, and hold Exact Match, its affiliates, subsidiaries, their respective officers, directors, employees, agents, contractors, successors, and assigns harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties arising from or relating to this Agreement, including, without limitation, any claims relating to the Services and Customer Content, and Authorized User breach of the terms of this Agreement, any end user agreement, or applicable law.</p>
                <p><br/></p>
                <p><strong>9.2. </strong>Exact Match shall defend, indemnify, and hold Customer harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties that arises from Exact Match&rsquo; provision of the Services in an allegedly unlawful manner or in violation of this Agreement.&nbsp;</p>
                <p><br/></p>
                <p><strong>9.3. </strong>If a third party asserts a claim against Customer that the Services offered by Exact Match infringes a patent, copyright, or trademark, Exact Match will defend Customer against that claim and pay amounts finally awarded by a court against Customer or included in a settlement approved by Exact Match, provided that Customer promptly (a) notifies Exact Match in writing of the claim, (b) supplies information requested by Exact Match, and (c) allows Exact Match to control, and reasonably cooperates in, the defense, settlement, and mitigation.</p>
                <p><br/></p>
                <p><strong>9.4. </strong>Exact Match&rsquo;s obligation to defend does not apply to any claim based on (a) Customer&rsquo;s combination of Services with data, software, or documentation not supplied, recommended, documented, or approved by Exact Match; or (b) Customer&rsquo;s unauthorized modifications to the Services; or (c) Customer&rsquo;s own Customer Content.</p>
                <p><br/></p>
                <p><strong>9.5. </strong>This Section describes Customer&rsquo;s sole remedy against Exact Match relating to third-party claims of patent, copyright, or trademark infringement.</p>
                <p><br/></p>
                <p class="bold">10. Limitation of Liability.</p>
                <p><br/></p>
                <p><strong>10.1.</strong> WHERE PERMITTED BY APPLICABLE LAW, NEITHER PARTY IS LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS, REPUTATION, OPPORTUNITIES, VALUE, REVENUE, GOODWILL, OR ANTICIPATED SAVINGS; OR COST OF REPLACEMENT SERVICES. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER EITHER PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. WHERE PERMITTED BY APPLICABLE LAW, EXACT MATCH MARKETING'S ENTIRE LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT WILL NOT EXCEED 6 (SIX) MONTHS&rsquo; SERVICES FEES PAID BY CUSTOMER TO EXACT MATCH MARKETING, REGARDLESS OF THE BASIS OF THE CLAIM.</p>
                <p><br/></p>
                <p><strong>10.2.</strong> THE LIMITATIONS IN SECTION 10.1 WILL NOT APPLY TO: (1) EITHER PARTY&rsquo;S BREACH OF CONFIDENTIALITY; (2) THE PARTIES&rsquo; INDEMNIFICATION OBLIGATIONS; (3) CUSTOMER&rsquo;S VIOLATION OF SECTION 1 (SERVICES) OR SECTION 2 OF THIS AGREEMENT (CUSTOMER RESPONSIBILITIES); (4) CUSTOMER&rsquo;S INFRINGEMENT OF EXACT MATCH&rsquo;S INTELLECTUAL PROPERTY OR MISAPPROPRIATION; (5) GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>
                <p><br/></p>
                <p class="bold">11. Privacy and Security.</p>
                <p><br/></p>
                <p><strong>11.1. </strong>All access to and use of the Services and use of the Platform is subject to Exact Match&lsquo;s Privacy Policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;), a current copy of which is available at https://exactmatchmarketing.com/index.php/privacy-policy/. Customer agrees to comply with the current terms of the Privacy Policy. Exact Match may collect, use, share, and store data made available via the Platform in connection with the Services hereunder and in accordance with Exact Match&rsquo;s policies and applicable laws.</p>
                <p><br/></p>
                <p><strong>11.2. </strong>Customer shall&nbsp;comply&nbsp;with all applicable privacy laws and respect the privacy rights of individuals. Customer shall provide individuals with a privacy notice required for the processing of their personal data and use, maintain, and document appropriate legal purposes and means for processing personal data. Customer shall use adequate means to transfer personal data where required to do so and obtain the consent of individuals when using personal data in a manner inconsistent with the notice provided to them at the time of collection.</p>
                <p><br/></p>
                <p><strong>11.3. </strong>Customer shall allow individuals to exercise their rights under applicable law, including, without limitation, restricting processing, deleting, or opting out of sale of personal information; or opting out of receiving emails, calls, or other communications from it. Customer shall maintain exclusionary lists relating to individuals who do not wish to receive emails, calls, or other communications from it. Customer shall comply with applicable do not call and do not email lists.</p>
                <p><br/></p>
                <p class="bold">12. Changes.</p>
                <p><br/></p>
                <p><strong>12.1. </strong>Exact Match may, at its reasonable discretion, modify the Services from time to time, with prior written notice where practicable, without any additional liability. Exact Match&rsquo;s modification to the Services will replace prior versions as of the effective date. Customer may not refuse these modifications to the Services. Notwithstanding the foregoing, Exact Match is not required to modify the Services.</p>
                <p><br/></p>
                <p><strong>12.2. </strong>Exact Match may update its Terms of Use and Privacy Policy from time to time in accordance with their terms.</p>
                <p><br/></p>
                <p><strong>12.3. </strong>Except as otherwise provided, all changes to this Agreement must be in writing and signed by both Parties.</p>
                <p><br/></p>
                <p class="bold">13. Term and Termination.</p>
                <p><br/></p>
                <p><strong>13.1. </strong>The term of this Agreement shall commence on the earlier of (a) the date of acceptance of this Agreement on behalf of Customer or (b) the date of first use of the Services on behalf of Customer. Termination of this Agreement by either party automatically terminates all orders in the applicable Order Form.&nbsp;</p>
                <p><br/></p>
                <p><strong>13.2. </strong>Services renew annually and automatically unless Customer provides written notice to Exact Match not to renew at least 60 calendar days prior to the annual renewal.</p>
                <p><br/></p>
                <p><strong>13.3. </strong>Exact Match may immediately, in its sole discretion and without prior written notice, temporarily suspend or limit Customer's use of the Services or this Agreement where Exact Match reasonably suspects a breach of this Agreement. Exact Match shall provide notice of the actions Customer must take to reinstate the Services. Exact Match may terminate the Services or this Agreement without any additional liability for Customer&rsquo;s failure to take required actions.</p>
                <p><br/></p>
                <p><strong>13.4. </strong>Exact Match may terminate an order or this Agreement without cause on at least 90 days&rsquo; written notice to the Customer.</p>
                <p><br/></p>
                <p><strong>13.5. </strong>This Agreement shall automatically terminate upon the liquidation or insolvency of either Party or the appointment of a trustee or receiver for either Party.</p>
                <p><br/></p>
                <p><strong>13.6. </strong>Upon termination of this Agreement or order described in the applicable Order Form: (a) Exact Match Marketing may immediately terminate Customer&rsquo;s access to the Services; and (b) Customer shall immediately cease using any portion of the Services.</p>
                <p><br/></p>
                <p><strong>13.7. </strong>Customer may not terminate this Agreement for convenience, except as otherwise stated in this Agreement. Either party may terminate this Agreement for material breach upon 30 calendar days&rsquo; prior written notice, provided that the breaching party does not cure it within the 30-calendar day notice period. Customer&rsquo;s failure to timely pay Exact Match constitutes a material breach of this Agreement.</p>
                <p><br/></p>
                <p><strong>13.8. </strong>If this Agreement is terminated for any reason (except for a material breach by Exact Match), Customer shall pay to Exact Match, on the date of termination, the total amount outstanding under this Agreement, including any fees for any remaining time period of a subscription or license. If Exact Match terminates this Agreement for a reason other than Customer&rsquo;s material breach, or if this Agreement is terminated for Exact Match&rsquo;s material breach, Exact Match shall provide Customer a pro rata refund for any prepaid and unearned fees.</p>
                <p><br/></p>
                <p><strong>13.9. </strong>After termination of this Agreement, Customer shall securely delete or destroy the Materials it has already incorporated into its databases or used for its business purposes. Upon request, Customer shall certify such deletion or destruction by its Chief Information Technology Officer, or equivalent, of Customer. Exact Match may audit Customer&rsquo;s compliance with these obligations and the license upon 5 days&rsquo; prior written notice.</p>
                <p><br/></p>
                <p><strong>13.10. </strong> After termination of this Agreement, except as otherwise provided in the applicable Order Form, Exact Match may retain de-identified Customer Content incorporated into its Services, and Customer hereby grants Exact Match a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process this Customer Content to provide or improve Exact Match&rsquo;s Services or for its business purposes.</p>
                <p><br/></p>
                <p class="bold">14. Governing Law.</p>
                <p><br/></p>
                <p><strong>14.1. </strong>This Agreement is governed by the laws of the State of Ohio, United States, without regard to conflict of law principles. The parties submit to the exclusive jurisdiction of, and venue in, the state or federal courts located in Franklin County, Ohio, in any action or proceeding arising from or relating to this Agreement. The United Nations Convention for the International Sale of Goods does not apply to this Agreement.</p>
                <p><br/></p>
                <p><strong>14.2. </strong>Either party may seek injunctive or other equitable relief for actual or threatened breach of confidentiality, security, or intellectual property protections hereunder by Customer under this Agreement.</p>
                <p><br/></p>
                <p><strong>14.3. </strong>Each Party irrevocably waives, to the fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding arising from or relating to this Agreement.</p>
                <p><br/></p>
                <p><strong>15. Audit.</strong></p>
                <p><br/></p>
                <p>Customer hereby agrees that upon reasonable notice and at a mutually agreeable time, Exact Match may periodically audit Customer&rsquo;s books and records relevant to the use of the Services in this Agreement in order to ensure compliance with the terms hereof. The third-party data providers of Exact Match shall be deemed intended third-party beneficiaries of this provision and shall also be entitled to investigate all legitimate reports of abuse or misuse of their Service. Violations discovered in any review will be subject to immediate action including, but not limited to, termination of Customer&rsquo;s right to use the Services, legal action, and/or referral to federal or state regulatory agencies.</p>
                <p><br/></p>
                <p class="bold">16. General.</p>
                <p><br/></p>
                <p><strong>16.1. </strong>This Agreement constitutes the entire agreement of the Parties and supersedes all prior or contemporaneous understandings, representations, discussions, or agreements between the Parties relating to its subject matter.</p>
                <p><br/></p>
                <p><strong>16.2. </strong>In the event of a conflict between the Terms of Use and this Agreement, this Agreement governs. If there is a conflict between the Order Form and this Agreement, the Order Form governs.</p>
                <p><br/></p>
                <p><strong>16.3. </strong>If any provision of this Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.</p>
                <p><br/></p>
                <p><strong>16.4. </strong>The waiver of a breach of any term of this Agreement, which must be in writing, will not operate as or be construed to be a waiver of any other previous or subsequent breach of this Agreement.</p>
                <p><br/></p>
                <p><strong>16.5. </strong>Customer and Exact Match agree not to hire or attempt to hire for employment, either directly or indirectly, an employee, agent, client, customer, supplier, or contractor of either Party during the term of this Agreement and for a period of 2 years after termination of this Agreement.</p>
                <p><br/></p>
                <p><strong>16.6. </strong>Exact Match is an independent contractor. Customer is responsible for its use of the Services. Each Party is responsible for determining the assignment of its and its affiliates&rsquo; personnel, and their respective contractors and vendors, and for their direction, control, and compensation.</p>
                <p><br/></p>
                <p><strong>16.7. </strong>Exact Match may collect information relating to Customer&rsquo;s use of the Services. Exact Match may internally use this information for providing or improving the Services.</p>
                <p><br/></p>
                <p><strong>16.8. </strong>Except as otherwise provided herein, Customer may not assign this Agreement, in whole or in part, without the prior written consent of Exact Match. Customer may assign this Agreement with 30 calendar days&rsquo; prior written notice to Exact Match upon a merger, acquisition, or purchase or sale of substantially all its assets so long as such transaction is not with a competitor of Exact Match. Exact Match may assign this Agreement at its sole discretion. Any assignment, transfer, or delegation of this Agreement in violation of this section is void.</p>
                <p><br/></p>
                <p><strong>16.9. </strong>All notices and other communications under this Agreement must be in writing and sent to the business address specified in this Agreement or to such other address as shall be advised in writing. All notices and other communications under this Agreement shall be deemed to have been given on the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next operating business day if sent after normal business hours of the recipient or if mailed, on the third day after the date mailed, by certified or registered mail (in each case, return receipt requested, postage pre-paid).&nbsp;</p>
                <p><br/></p>
                <p style="margin-left:20px"><strong>Exact Match Marketing, Inc.</strong></p>
                <p style="margin-left:20px">Email: <a href="mailto:josh@exactmatchmarketing.com">josh@exactmatchmarketing.com</a>&nbsp;</p>
                <p style="margin-left:20px">Attention: Josh Rockey</p>
                <p style="margin-left:20px">Address: 895 Dietrich Ct Newark OH 43055</p>
                <p><br/></p>
                <p><strong>16.10. </strong>This Agreement may be executed in two or more counterparts, all of which when taken together shall be considered one and the same agreement and become effective when counterparts have been signed by each Party and delivered to the other Party.</p>
                <p><br/></p>
                <p><strong>16.11. </strong>The Parties agree that the electronic signatures of the Parties included in this Agreement are intended to authenticate this writing and to have the same force and effect as manual signatures. Electronic Signature means any electronic sound, symbol, or process attached to or logically associated with a record and executed and adopted by a party with the intent to sign such record, including clicking a button or checking a box next to the statement &ldquo;I Agree&rdquo;, pursuant to the Ohio Uniform Electronic Transactions Act (R.C. 1306.01 et seq.) as amended from time to time. The Parties consent to the use of electronic signatures and communication. Any reproduction of this Agreement made by reliable means is considered an original.</p>
                <p><br/></p>
                <p><strong>16.12. </strong>This Agreement does not create any third-party rights. Neither Party will bring a legal action arising from, or relating to this Agreement, more than two years after the cause of action arose.</p>
                <p><br/></p>
                <p><strong>16.13. </strong>Any terms that by their nature extend beyond this Agreement termination remain in effect until satisfied and apply to successors and assignees.</p>
                <p><br/></p>
                <p><strong>16.14. </strong>Unless Customer requests otherwise in writing in advance, Customer authorizes Exact Match to use Customer&rsquo;s logo, name, or trademark on its website or other media as a customer.</p>
                <p><br/></p>
                <p><strong>16.15. </strong>The Parties hereto are not responsible for failure to fulfill its obligations under this Agreement due to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may not be delayed under such causes beyond 15 calendar days.</p>
                <p><br/></p>
                <p><strong>16.16. </strong>The Parties hereto are sophisticated, commercial Parties. This Agreement will not be construed against the drafter.</p>
                <p><br/></p>
                <p><strong>16.17 </strong>Parties acknowledge that they have read this Agreement, understand it, and agree to be bound by its terms. The person signing on behalf of each Party is authorized to do so.</p>
                <p><br/></p>
              </div>  
  <div v-if="platform" class="container">
        <p class="bold text-center">{{this.$global.companyrootname}} White Label Platform<br/>Services Agreement</p>
        <p><br></p>
        <p class="text-center"><strong><u>IMPORTANT &ndash; READ CAREFULLY</u></strong></p>
        <p class="bold" style="text-transform: uppercase">THIS SERVICES AGREEMENT (ALONG WITH THE ORDER FORM, THE TERMS OF USE, AND THE PRIVACY POLICY, COLLECTIVELY THIS &ldquo;AGREEMENT&rdquo;), is by and between {{this.$global.companyrootname}} Inc., an {{this.$global.companyrootstatename}} company located at {{this.$global.companyrootaddres}}, {{this.$global.companyrootcity}}, {{this.$global.companyrootstatename}} {{this.$global.companyrootzip}} (&ldquo;{{this.$global.companyrootnameshort}}&rdquo;) AND THE ENTITY OR INDIVIDUAL ON WHOSE BEHALF THE SERVICES (AS DEFINED BELOW) ARE BEING ACCESSED OR USED (“CUSTOMER”). EACH MAY BE REFERRED TO HEREIN COLLECTIVELY AS THE “PARTIES” OR EACH INDIVIDUALLY AS A “PARTY.”</p>
        <p><br></p>
        <p><strong>BY INDICATING ACCEPTANCE OF THIS AGREEMENT, EITHER BY CLICKING ON THE &ldquo;AGREE&rdquo; BUTTON, DURING THE ACCOUNT SETUP PROCESS OR USING THE SERVICES, (&ldquo;EFFECTIVE DATE&rdquo;) YOU ARE AGREEING THAT CUSTOMER IS BOUND BY AND USE OF THE SERVICES ON BEHALF OF THE CUSTOMER IS GOVERNED BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF CUSTOMER DOES NOT ACCEPT THIS AGREEMENT, CUSTOMER MAY NOT ACCESS OR USE THE SERVICES.</strong></p>
        <p><br></p>
        <p class="bold">RECITALS</p>
        <p><br></p>
        <p>WHEREAS, {{this.$global.companyrootnameshort}} has developed a proprietary software offering services to generate leads and disseminate and track advertising and marketing materials that can be used to grow specific needs of a business, and</p>
        <p><br></p>
        <p>WHEREAS, Customer wishes to subscribe to and license {{this.$global.companyrootnameshort}}&rsquo;s software as a white label Platform to authorized users pursuant to this Agreement, and&nbsp;</p>
        <p><br></p>
        <p>NOW, THEREFORE, acknowledging the receipt of adequate consideration and intending to be legally bound, the Parties agree as follows:</p>
        <p><br></p>
        <p class="bold">1. Services.</p>
        <p><br/></p>
        <p><strong>1.1.</strong> &ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted by the Parties.&nbsp;</p>
        <p><br/></p>
        <p><strong>1.2.</strong> &ldquo;Platform&rdquo; means the proprietary software-as-a-service managed by {{this.$global.companyrootnameshort}}, in both source code and object code form, components, documentations, implementation, integration and improvements thereof, provided to Customer as described in the Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to Customer or any Authorized User (as defined below) in connection with the foregoing.&nbsp;</p>
        <p><br/></p>
        <p><strong>1.3.</strong> &ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including {{this.$global.companyrootnameshort}}&rsquo;s subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that {{this.$global.companyrootnameshort}} provides to Customer through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
        <p><br/></p>
        <p><strong>1.4.</strong> Customer shall be entitled to a trial period of sixty (60) days commencing on the Effective Date at no charge (the &ldquo;Free Trial Period&rdquo;). At any time prior to expiration of the Free Trial Period, Customer may elect to cancel this Agreement by providing written notice to {{this.$global.companyrootnameshort}} (&ldquo;Trial Cancellation Notice&rdquo;). If Customers does not provide the Trial Cancellation Notice, then Customer agrees to the terms the Agreement and to pay all applicable charges according to the Agreement.</p>
        <p><br/></p>
        <p><strong>1.5.</strong> The Services are available only to Authorized Users. “Authorized User” means anyone, including end users, who Customer gives permission to access and use the Platform in accordance with this Agreement.</p>
        <p><br/></p>
        <p><strong>1.6.</strong> Customer may access the Services only to the extent authorized by {{this.$global.companyrootnameshort}}. Customer is responsible for use of Services by its Authorized Users and other users, whether or not authorized, that access or use the Services using the accounts or credentials of Customer or any Authorized User, and Customer will be liable for any action or omission of any of the preceding that would violate this agreement as if such action or omission were taken by Customer itself. Customer shall ensure that Authorized Users comply with {{this.$global.companyrootnameshort}}&rsquo;s Terms of Use, found at&nbsp;https://{{this.$global.companyrootdomain}}/terms-of-use/, (the &ldquo;Terms of Use&rdquo;) which are incorporated herein by reference, when using the Services. Customer acknowledges that it has read and agrees to be bound by the Terms of Use and will properly describe to Authorized Users all capabilities and limitations of the Platform. Customer is responsible for Customer&rsquo;s and its Authorized User&rsquo;s actions and the contents of its transmissions through the Services. These Authorized Users, whether located in or outside of the U.S., and their usage of the Platform are Customer&rsquo;s sole responsibility, and Customer must comply with all applicable laws and security protocols pertaining to their access, including applicable export laws, restrictions, and regulations. For the avoidance of doubt, the Platform should not be made available or copied/distributed to prohibited countries, persons or entities, as indicated in an Office of Foreign Assets Control (OFAC) of the US Department of the Treasury list.</p>
        <p><br/></p>
        <p><strong>1.7.</strong> Customer may not, and will ensure that its Authorized User&rsquo;s do not: (a) duplicate, disassemble, decompile, reverse engineer, or otherwise reproduce without authorization any portion of the Services or source code for the Platform; (b) resell, sublicense or allow any third party (other than Authorized Users, for whom Customer shall be wholly responsible) to use the Services, except pursuant to the normal operation of the Platform; (c) scrape, steal, or copy without authorization the Services; (d) disclose any performance data relating to the Services; (e) sell or transfer to another third party the Services in violation of this Agreement; (f) build a product, service, or offering that competes with {{this.$global.companyrootnameshort}} or Services; (g) use the name or proprietary logo(s) of {{this.$global.companyrootnameshort}} without {{this.$global.companyrootnameshort}}&rsquo;s prior written consent; (h) use the Services for any purpose other than the operation of the Platform; or (i) use the Services in a manner that interferes with the use of the Platform by {{this.$global.companyrootnameshort}}, its other customers.</p>
        <p><br/></p>
        <p><strong>1.8.</strong> Customer may not and will ensure that its Authorized User’s do not: use the Services for: (a) defamatory, harassing, abusive, threatening, obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that violates or infringes upon the rights of third parties; (c) activity that violates applicable law; (d) sending viruses or spyware or similar computer programming routines; or (e) any purposes inconsistent with this Agreement or which violate the Terms of Use and Privacy Policy.</p>
        <p><br></p>
        <p class="bold">2. Customer Responsibilities.</p>
        <p><br/></p>
        <p><strong>2.1.</strong> This Agreement and Services are a valuable trade secret and confidential proprietary property of {{this.$global.companyrootnameshort}}. Customer agrees to access and use the Services only as provided in this Agreement and to safeguard {{this.$global.companyrootnameshort}}&rsquo;s &nbsp;trade secrets and confidential proprietary property. Customer agrees to delete the Materials upon termination of this Agreement, unless mutually agreed otherwise in writing.</p>
        <p><br/></p>
        <p><strong>2.2.</strong> Customer is hereby provided with limited access to the Services subject to this Agreement. Subject to the terms of this Agreement, {{this.$global.companyrootnameshort}} hereby provides to Customer a limited, non-exclusive, non-transferrable, revocable-at-any-time license during the term stated in the applicable Order Form to access and use the Materials for its ordinary business purposes, (the &ldquo;License&rdquo;). Except as otherwise stated in this section or on the Order Form, Customer does not obtain any other rights to the Services or Materials. Customer&rsquo;s License to use the Materials is only valid for six (6) months from purchase (or less if required by {{this.$global.companyrootnameshort}} data providers, as set forth in more detail in the Order Form).&nbsp;</p>
        <p><br/></p>
        <p><strong>2.3.</strong> Customer shall: (i) not use for solicitation the name, mailing address or telephone number of a consumer that is designated within the Service as requesting protection from solicitation, (ii) abide by all prevailing federal, state, and local laws and regulations governing fair information practices and consumers&rsquo; rights to privacy, and (iii) limit access to consumer information to those individuals who have a &ldquo;need to know&rdquo; in connection with Customer&rsquo;s business and will obligate those individuals to acknowledge consumers&rsquo; rights to privacy and adhere to fair information practices and consumer&rsquo;s right to privacy.</p>
        <p><br/></p>
        <p><strong>2.4.</strong> &ldquo;Customer Content&rdquo; means all content, information, or data that Customer provides or authorizes access to for the Services. Except as otherwise provided in the Order Form, Customer hereby grants {{this.$global.companyrootnameshort}} &nbsp;a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process de-identified Customer Content to provide or improve {{this.$global.companyrootnameshort}}&rsquo;s Services or for its business purposes. Customer warrants that it has the right and authority to provide Customer Content and that such materials do not infringe the rights of others or violate applicable law.</p>
        <p><br/></p>
        <p><strong>2.5.</strong> Customer is responsible for obtaining all necessary rights and permissions to enable, and grants such rights and permissions to, {{this.$global.companyrootnameshort}}, its affiliates, and their respective contractors and vendors to use, provide, store and otherwise process Customer Content in the Services. This includes Customer making necessary disclosures and obtaining consent, if required, before providing individuals&rsquo; information to {{this.$global.companyrootnameshort}}.</p>
        <p><br/></p>
        <p><strong>2.6.</strong> Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s intended use; (b) taking necessary actions to order, enable, or use available features appropriate for its use of the Services; (c) complying with applicable law; and (d) the compliance of Customer Content with this Agreement, including content uploaded by its Authorized Users.</p>
        <p><br/></p>
        <p><strong>2.7. </strong>Customer shall cause each of its Authorized users to enter into an end-user agreement that, at a  minimum, provides that:</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.1.	The Platform is only licensed to an end user for the end user’s non-transferable and non-exclusive use, without the right to sublicense;</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.2.	no right, title, or interest to the Platform, and any intellectual property rights in the Platform, is transferred to the end user;</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.3.	except to the limited extent as may be permitted under applicable law, the end user agrees not to modify, translate, generate derivative works from or reverse assemble, decompile, or otherwise attempt to derive source code from the Platform;</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.4.	the Platform is subject to Company’s copyrights and Company owns all intellectual property rights in the Platform. In some instances although copyrighted, are unpublished and contain proprietary and confidential information of Company and are considered by Company to be trade secrets and the end user agrees to hold the Platform in confidence and the end user further agrees to take all reasonable precautions to safeguard the confidentiality of such Platform.  The end user further agrees not to use the Platform to develop a competitive product or for any other purpose, except for its own internal business needs;</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.5.	The end user’s rights with respect to the Platform may be terminated should the end user breach any terms of the end user agreement and fail to cure such breach within thirty (30) days after written notice;</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.6.	Company is an expressly intended third-party beneficiary of the end user agreement and shall have the right to enforce its rights directly against the end user; and</p>
        <p><br/></p>
        <p style="margin-left: 1rem;">2.7.7.	Use of the Platform by Customer’s Authorized Users is also subject to Platform Terms of Use and Platform Privacy Policy between Customer and Authorized Users, and Customer agrees that such Platform Terms of Use and Platform Privacy Policy will: (i) be included as at least the minimum end-user terms; and (ii) will be accepted by each Authorized User prior to their use of the Platform. Customer shall ensure that all Authorized Users are aware of: (a) this Agreement’s provisions as applicable to the Authorized Users of the Platform; and (b) the Platform Terms of Use and the Platform Privacy Policy and their applicability to the Authorized Users of the Platform, and shall ensure that the Authorized Users comply with such provisions of this Agreement and the Terms of Use and Privacy Policy.</p>
        <p><br/></p>
        <p class="bold">3. Charges, Taxes, and Payment.</p>
        <p><br/></p>
        <p><strong>3.1. </strong> Customer agrees to spend a minimum $500 per month during the term of this Agreement, (the &ldquo;Minimum Purchase Commitment&rdquo; or &ldquo;MPC&rdquo;), in accordance with the terms of this Agreement. If Customer fails to purchase a minimum $500 per month to meet the MPC in any given month, then Customer shall be invoiced according to the Agreement an amount equal to the shortfall.</p>
        <p><br/></p>
        <p><strong>3.2. </strong> Customer shall pay all applicable charges and fees specified for the Services under this Agreement as selected by Customer within the Platform for the selected Services and any charges imposed by any authority resulting from Customer's use of the Services, including any applicable sales taxes. The charges described in this Agreement and the Order Form are exclusive of any federal, state, municipal, or foreign taxes, levies, duties or other governmental assessments of any nature, including excise, sales, use, value-added, gross receipts, personal property, occupational, or other taxes (collectively, “Taxes”) in the United States or applicable countries. Customer is responsible for paying all Taxes associated with its purchases hereunder, excluding taxes based on {{this.$global.companyrootnameshort}}’s income or property.</p>
        <p><br/></p>
        <p><strong>3.3. </strong> Customer agrees to set up automatic payments in amounts due as set forth in this Agreement and the Order Form through {{this.$global.companyrootnameshort}}’s third-party payment processor. To do so, Customer shall provide a valid credit card number or establish ACH funds transfer prior to obtaining access to the Services. Customer shall ensure adequate funds are available to pay any amounts due hereunder.</p>
        <p><br/></p>
        <p><strong>3.4. </strong> All subscriptions are annual with monthly or annual billing payments. Except as otherwise stated in this Agreement, all subscription purchases are final and non-refundable. Annual subscription renewal cancellation requests are subject to the terms set forth in this Agreement.</p>
        <p><br/></p>
        <p><strong>3.5. </strong> Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not paid within 30 calendar days of the invoice date. {{this.$global.companyrootnameshort}} may, at its sole discretion, terminate this Agreement if Customer has failed to pay any amount due for more than 30 calendar days.</p>
        <p><br></p>
        <p class="bold">4. Confidentiality.</p>
        <p><br></p>
        <p><strong>4.1. </strong> &ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary information, formulae, processes, techniques, and information concerning past, present, and future marketing, financial, research, and development activities that may be disclosed, orally or in writing, to each other. Confidential Information excludes information that was (a) previously known to the receiving party without an obligation of confidence; (b) independently developed by or for the receiving party without the use of Confidential Information; (c) lawfully acquired by the receiving party from a third party which is not under an obligation of confidence with respect to such information; or (d) or becomes publicly available through no fault of the receiving party without a breach of this Agreement.</p>
        <p><br></p>
        <p><strong>4.2. </strong> Customer shall maintain the privacy, security, and confidentiality of Confidential Information and its access to the Services. A temporary password will be given upon account creation. Customer agrees to immediately reset their initial password with a strong password. Customer shall use strong and secure passwords and keep them secure and confidential. Customer shall promptly notify {{this.$global.companyrootnameshort}} in the event of a security breach or unauthorized use of their account. Customer is responsible for any damages incurred as a result of the unauthorized use of their account.</p>
        <p><br></p>
        <p><strong>4.3. </strong> {{this.$global.companyrootnameshort}} shall use reasonable efforts to maintain the privacy, security, and confidentiality of Confidential Information in accordance with this Agreement and its policies.</p>
        <p><br></p>
        <p><strong>4.4. </strong> During the term of this Agreement and at all times thereafter in which Confidential Information remains confidential, neither Party shall disclose Confidential Information of the other Party or use such Confidential Information for any purpose other than in furtherance of this Agreement. Without limiting the preceding sentence, each party shall use at least the same degree of care in safeguarding the other Party&rsquo;s Confidential Information as it uses to safeguard its own Confidential Information. Notwithstanding the foregoing a Party may disclose Confidential Information (a) if required to do so by legal process (i.e., by a subpoena), provided that such Party shall notify the other Party prior to such disclosure so that such other Party may attempt to prevent such disclosure or seek a protective order; or (b) to any applicable governmental authority as required in the operation of such Party&rsquo;s business, and even then, limited to no more than the minimum information required.</p>
        <p><br></p>
        <p class="bold">5. Ownership of Intellectual Property and Data.</p>
        <p><br></p>
        <p><strong>5.1. </strong> {{this.$global.companyrootnameshort}} is the exclusive owner of the Platform and Services, and all of the intellectual property associated with its Platform and Services, including software, copyrights, patents, trademarks, trade secrets, and other data not owned by Customer, even if {{this.$global.companyrootnameshort}} incorporates suggestions made by Customer. Customer shall not receive any form of compensation for ideas, modifications, suggestions, or improvements made to {{this.$global.companyrootnameshort}}&rsquo;s Platform or Services. Except as expressly set forth in this Agreement, Customer is granted no license or other rights in or to any {{this.$global.companyrootnameshort}} data or any use thereof.&nbsp;</p>
        <p><br></p>
        <p><strong>5.2. </strong> Customer shall own and retain all &nbsp;right, title and interest in and to any data, information, and other content, including any text, files, images, graphics, illustrations, information, data, audio, video, photographs, completed program performance and analytics provided by Customer or Customer&rsquo;s Authorized Users. (&ldquo;Customer Content&rdquo;).</p>
        <p><br></p>
        <p><strong>5.3. </strong> Customer grants {{this.$global.companyrootnameshort}} all rights and licenses in and to the Customer Content as may be useful or reasonably necessary for {{this.$global.companyrootnameshort}} to provide the Services and perform its other obligations under this Agreement. Customer has all rights in and to all Customer Content necessary to permit {{this.$global.companyrootnameshort}} to provide the Services and perform its other obligations under this Agreement.&nbsp;</p>
        <p><br></p>
        <p class="bold">6. White-Label Content and Branding.</p>
        <p><br></p>
        <p><strong>6.1. </strong> &ldquo;White-Label Content&rdquo; means any Customer Content that Customer provides to the Platform, as new or replacement material for the purpose of white labeling Customer&rsquo;s instance of the Platform.</p>
        <p><br></p>
        <p><strong>6.2. </strong> Platform branding includes incorporation of Customer&rsquo;s Data including Customer&rsquo;s name and logo and skinning of the site in Customer&rsquo;s selected primary and secondary colors. The standard Platform URL will appear with Customer&rsquo;s selected word as a subdomain of {{this.$global.companyrootnameshort}} (e.g. Name.{{this.$global.companyrootdomain}}) unless Customer requests a custom URL. Custom or &lsquo;vanity&rsquo; URLs, which shall include provision of a Secure Socket Layer (SSL), are available upon request. Customer is responsible for clearing any privacy rights, copyright, trademark or other intellectual property concerns connected with a custom URL as well as securing the URL via a registrar service.</p>
        <p><br></p>
        <p><strong>6.3. </strong> Customer agrees to collaborate with {{this.$global.companyrootnameshort}} on initial implementation of White-Label Content. Customer will be responsible for editing and maintaining the White-Label Content after this initial implementation. &nbsp;White-Label Content changes may include customizing setup categories, tasks, content, related resources, assessments, and other content changes that fit within the Platform&rsquo;s existing functions, as may be identified and changed by {{this.$global.companyrootnameshort}} from time to time and at {{this.$global.companyrootnameshort}}&rsquo;s sole discretion.</p>
        <p><br></p>
        <p><strong>6.4. </strong> After the initial implementation, Customer shall ensure that no Authorized Users remove, delete, alter, or obscure any trademarks, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Platform without the prior written consent of {{this.$global.companyrootnameshort}}.&nbsp;A copyright notice with {{this.$global.companyrootnameshort}}&rsquo;s name and Terms of Use, provided at https://{{this.$global.companyrootdomain}}/terms-of-use/, shall appear on the Platform.</p>
        <p><br></p>
        <p><strong>6.5. </strong> Customer acknowledges that charged or invoiced fees as stated in the Agreement and Order Form may include additional costs related to support, training, and other services related to the Platform and the Services. &nbsp;Unless otherwise provided in an Order Form, such additional support, training, and other services will be invoiced to Customer on a time and materials basis, at {{this.$global.companyrootnameshort}}&rsquo;s then-standard hourly rates. &nbsp;</p>
        <p><br></p>
        <p class="bold">7. Warranties.</p>
        <p><br></p>
        <p>Each party warrants to the other that this Agreement has been duly executed and delivered and constitutes a valid and binding agreement enforceable against such party in accordance with its terms.</p>
        <p><br></p>
        <p class="bold">8. Warranty Disclaimer.</p>
        <p><br></p>
        <p style="text-transform: uppercase">THE SERVICES PROVIDED ARE &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT APPLY. NEITHER {{this.$global.companyrootname}} NOR ITS THIRD-PARTY PROVIDERS ASSURES OR ASSUMES ANY LIABILITY TO ANY PERSON OR ENTITY FOR THE PROPER PERFORMANCE OF SERVICES NECESSARY TO THE CONDUCT OF A REAL ESTATE CLOSING. &nbsp;NEITHER {{this.$global.companyrootname}} NOR ITS THIRD-PARTY PROVIDER REPRESENT OR WARRANT (a) UNINTERRUPTED, TIMELY, OR ERROR-FREE SERVICES, (b) THAT {{this.$global.companyrootname}} WILL CORRECT ANY DEFECTS OR PREVENT THIRD-PARTY DISRUPTIONS OR UNAUTHORIZED THIRD-PARTY ACCESS, OR (c) THAT SERVICES ARE SECURE, AVAILABLE, ACCURATE, PRIVATE, CONFIDENTIAL, APPROPRIATE, RELIABLE, OR COMPLETE. NEITHER {{this.$global.companyrootname}} NOR ITS THIRD-PARTY PROVIDERS DO NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE, ACCIDENT, OR OTHER CAUSE. &nbsp;</p>
        <p><br></p>
        <p class="bold">9. Indemnity.</p>
        <p><br></p>
        <p><strong>9.1. </strong> Customer shall defend, indemnify, and hold {{this.$global.companyrootnameshort}}, its affiliates, subsidiaries, their respective officers, directors, employees, agents, contractors, successors, and assigns harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties arising from or relating to this Agreement, including, without limitation, any claims relating to the Services and Customer Content, and Authorized User breach of the terms of this Agreement, any end user agreement, or applicable law.</p>
        <p><br></p>
        <p><strong>9.2. </strong> {{this.$global.companyrootnameshort}} shall defend, indemnify, and hold Customer harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties that arises from {{this.$global.companyrootnameshort}}&rsquo; provision of the Services in an allegedly unlawful manner or in violation of this Agreement.&nbsp;</p>
        <p><br></p>
        <p><strong>9.3. </strong> If a third party asserts a claim against Customer that the Services offered by {{this.$global.companyrootnameshort}} &nbsp;infringes a patent, copyright, or trademark, {{this.$global.companyrootnameshort}} will defend Customer against that claim and pay amounts finally awarded by a court against Customer or included in a settlement approved by {{this.$global.companyrootnameshort}}, provided that Customer promptly (a) notifies {{this.$global.companyrootnameshort}} in writing of the claim, (b) supplies information requested by {{this.$global.companyrootnameshort}}, and (c) allows {{this.$global.companyrootnameshort}} to control, and reasonably cooperates in, the defense, settlement, and mitigation.</p>
        <p><br></p>
        <p><strong>9.4. </strong> {{this.$global.companyrootnameshort}}&rsquo;s obligation to defend does not apply to any claim based on (a) Customer&rsquo;s combination of Services with data, software, or documentation not supplied, recommended, documented, or approved by {{this.$global.companyrootnameshort}}; or (b) Customer&rsquo;s unauthorized modifications to the Services; or (c) Customer&rsquo;s own Customer Content.</p>
        <p><br></p>
        <p><strong>9.5. </strong> This Section describes Customer&rsquo;s sole remedy against {{this.$global.companyrootnameshort}} relating to third-party claims of patent, copyright, or trademark infringement.</p>
        <p><br></p>
        <p class="bold">10. Limitation of Liability.</p>
        <p><br></p>
        <p style="text-transform: uppercase"><strong>10.1. </strong> WHERE PERMITTED BY APPLICABLE LAW, NEITHER PARTY IS LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS, REPUTATION, OPPORTUNITIES, VALUE, REVENUE, GOODWILL, OR ANTICIPATED SAVINGS; OR COST OF REPLACEMENT SERVICES. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER EITHER PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. WHERE PERMITTED BY APPLICABLE LAW, {{this.$global.companyrootname}}&apos;S ENTIRE LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT WILL NOT EXCEED 6 (SIX) MONTHS&rsquo; SERVICES FEES PAID BY CUSTOMER TO {{this.$global.companyrootname}}, REGARDLESS OF THE BASIS OF THE CLAIM.</p>
        <p><br></p>
        <p style="text-transform: uppercase"><strong>10.2. </strong> THE LIMITATIONS IN SECTION 10.1 WILL NOT APPLY TO: (1) EITHER PARTY&rsquo;S BREACH OF CONFIDENTIALITY; (2) THE PARTIES&rsquo; INDEMNIFICATION OBLIGATIONS; (3) CUSTOMER&rsquo;S VIOLATION OF SECTION 1 (SERVICES) OR SECTION 2 OF THIS AGREEMENT (CUSTOMER RESPONSIBILITIES); (4) CUSTOMER&rsquo;S INFRINGEMENT OF {{this.$global.companyrootnameshort}}&rsquo;S INTELLECTUAL PROPERTY OR MISAPPROPRIATION; (5) GROSS NEGLIGENCE OR WILLFUL MISCONDUCT. &nbsp;</p>
        <p><br></p>
        <p class="bold">11. Privacy and Security.</p>
        <p><br/></p>
        <p><strong>11.1. </strong> All access to and use of the Services and use of the Platform is subject to {{this.$global.companyrootnameshort}}&lsquo;s Privacy Policy (&ldquo;Privacy Policy&rdquo;), a current copy of which is available at https://{{this.$global.companyrootdomain}}/privacy-policy/. Customer agrees to comply with the current terms of the Privacy Policy. &nbsp;{{this.$global.companyrootnameshort}} may collect, use, share, and store data made available via the Platform in connection with the Services hereunder and in accordance with {{this.$global.companyrootnameshort}}&rsquo;s policies and applicable laws.</p>
        <p><br/></p>
        <p><strong>11.2. </strong> Customer shall&nbsp;comply&nbsp;with all applicable privacy laws and respect the privacy rights of individuals. Customer shall provide individuals with a privacy notice required for the processing of their personal data and use, maintain, and document appropriate legal purposes and means for processing personal data. Customer shall use adequate means to transfer personal data where required to do so and obtain the consent of individuals when using personal data in a manner inconsistent with the notice provided to them at the time of collection.</p>
        <p><br/></p>
        <p><strong>11.3. </strong> Customer shall allow individuals to exercise their rights under applicable law, including, without limitation, restricting processing, deleting, or opting out of sale of personal information; or opting out of receiving emails, calls, or other communications from it. Customer shall maintain exclusionary lists relating to individuals who do not wish to receive emails, calls, or other communications from it. Customer shall comply with applicable do not call and do not email lists.</p>
        <p><br></p>
        <p class="bold">12. Changes.</p>
        <p><br></p>
        <p><strong>12.1. </strong> {{this.$global.companyrootnameshort}} may, at its reasonable discretion, modify the Services from time to time, with prior written notice where practicable, without any additional liability. {{this.$global.companyrootnameshort}}&rsquo;s modification to the Services will replace prior versions as of the effective date. Customer may not refuse these modifications to the Services. Notwithstanding the foregoing, {{this.$global.companyrootnameshort}} is not required to modify the Services.</p>
        <p><br></p>
        <p><strong>12.2. </strong> {{this.$global.companyrootnameshort}} may update its Terms of Use and Privacy Policy from time to time in accordance with their terms.</p>
        <p><br></p>
        <p><strong>12.3. </strong> Except as otherwise provided, all changes to this Agreement must be in writing and signed by both Parties.</p>
        <p><br></p>
        <p class="bold">13. Term and Termination.</p>
        <p><br></p>
        <p><strong>13.1. </strong> The term of this Agreement shall commence on the earlier of (a) the date of acceptance of this Agreement on behalf of Customer or (b) the date of first use of the Services on behalf of Customer. Termination of this Agreement by either party automatically terminates all orders in the applicable Order Form.</p>
        <p><br></p>
        <p><strong>13.2. </strong> Services renew annually and automatically unless Customer provides written notice to {{this.$global.companyrootnameshort}} not to renew at least 60 calendar days prior to the annual renewal.</p>
        <p><br></p>
        <p><strong>13.3. </strong> {{this.$global.companyrootnameshort}} may immediately, in its sole discretion and without prior written notice, temporarily suspend or limit Customer&apos;s use of the Services or this Agreement where {{this.$global.companyrootnameshort}} reasonably suspects a breach of this Agreement. {{this.$global.companyrootnameshort}} shall provide notice of the actions Customer must take to reinstate the Services. {{this.$global.companyrootnameshort}} may terminate the Services or this Agreement without any additional liability for Customer&rsquo;s failure to take required actions.</p>
        <p><br></p>
        <p><strong>13.4. </strong> {{this.$global.companyrootnameshort}} may terminate an order or this Agreement without cause on at least 90 days&rsquo; written notice to the Customer.</p>
        <p><br></p>
        <p><strong>13.5. </strong> This Agreement shall automatically terminate upon the liquidation or insolvency of either Party or the appointment of a trustee or receiver for either Party.</p>
        <p><br></p>
        <p><strong>13.6. </strong> Upon termination of this Agreement or order described in the applicable Order Form: (a) {{this.$global.companyrootname}} may immediately terminate Customer&rsquo;s access to the Services; and (b) Customer shall immediately cease using any portion of the Services.</p>
        <p><br></p>
        <p><strong>13.7. </strong> Customer may not terminate this Agreement for convenience, except as otherwise stated in this Agreement. Either party may terminate this Agreement for material breach upon 30 calendar days&rsquo; prior written notice, provided that the breaching party does not cure it within the 30-calendar day notice period. Customer&rsquo;s failure to timely pay {{this.$global.companyrootnameshort}} constitutes a material breach of this Agreement.</p>
        <p><br></p>
        <p><strong>13.8. </strong> If this Agreement is terminated for any reason (except for a material breach by {{this.$global.companyrootnameshort}}), Customer shall pay to {{this.$global.companyrootnameshort}}, on the date of termination, the total amount outstanding under this Agreement, including any fees for any remaining time period of a subscription or license. If {{this.$global.companyrootnameshort}} terminates this Agreement for a reason other than Customer&rsquo;s material breach, or if this Agreement is terminated for {{this.$global.companyrootnameshort}}&rsquo;s material breach, {{this.$global.companyrootnameshort}} shall provide Customer a pro rata refund for any prepaid and unearned fees.</p>
        <p><br></p>
        <p><strong>13.9. </strong> After termination of this Agreement, Customer shall securely delete or destroy the Materials it has already incorporated into its databases or used for its business purposes. Upon request, Customer shall certify such deletion or destruction by its Chief Information Technology Officer, or equivalent, of Customer. {{this.$global.companyrootnameshort}} may audit Customer&rsquo;s compliance with these obligations and the license upon 5 days&rsquo; prior written notice.</p>
        <p><br></p>
        <p><strong>13.10. </strong> After termination of this Agreement, except as otherwise provided in the applicable Order Form, {{this.$global.companyrootnameshort}} may retain de-identified Customer Content incorporated into its Services, and Customer hereby grants {{this.$global.companyrootnameshort}} a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process this Customer Content to provide or improve {{this.$global.companyrootnameshort}}&rsquo;s Services or for its business purposes.</p>
        <p><br></p>
        <p class="bold">14. Governing Law.</p>
        <p><br></p>
        <p><strong>14.1. </strong> This Agreement is governed by the laws of the State of Ohio, United States, without regard to conflict of law principles. The parties submit to the exclusive jurisdiction of, and venue in, the state or federal courts located in Franklin County, Ohio, in any action or proceeding arising from or relating to this Agreement. The United Nations Convention for the International Sale of Goods does not apply to this Agreement.</p>
        <p><br></p>
        <p><strong>14.2. </strong> Either party may seek injunctive or other equitable relief for actual or threatened breach of confidentiality, security, or intellectual property protections hereunder by Customer under this Agreement.</p>
        <p><br></p>
        <p><strong>14.3. </strong> Each Party irrevocably waives, to the fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding arising from or relating to this Agreement.</p>
        <p><br></p>
        <p class="bold">15. Audit.</p>
        <p><br></p>
        <p>Customer hereby agrees that upon reasonable notice and at a mutually agreeable time, {{this.$global.companyrootnameshort}} may periodically audit Customer&rsquo;s books and records relevant to the use of the Services in this Agreement in order to ensure compliance with the terms hereof. The third-party data providers of {{this.$global.companyrootnameshort}} shall be deemed intended third-party beneficiaries of this provision and shall also be entitled to investigate all legitimate reports of abuse or misuse of their Service. Violations discovered in any review will be subject to immediate action including, but not limited to, termination of Customer&rsquo;s right to use the Services, legal action, and/or referral to federal or state regulatory agencies.</p>
        <p><br></p>
        <p class="bold">16. General.</p>
        <p><br></p>
        <p><strong>16.1. </strong> This Agreement constitutes the entire agreement of the Parties and supersedes all prior or contemporaneous understandings, representations, discussions, or agreements between the Parties relating to its subject matter.</p>
        <p><br></p>
        <p><strong>16.2. </strong> In the event of a conflict between the Terms of Use and this Agreement, this Agreement governs. If there is a conflict between the Order Form and this Agreement, the Order Form governs.</p>
        <p><br></p>
        <p><strong>16.3. </strong> If any provision of this Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.</p>
        <p><br></p>
        <p><strong>16.4. </strong> The waiver of a breach of any term of this Agreement, which must be in writing, will not operate as or be construed to be a waiver of any other previous or subsequent breach of this Agreement.</p>
        <p><br></p>
        <p><strong>16.5. </strong> Customer and {{this.$global.companyrootnameshort}} agree not to hire or attempt to hire for employment, either directly or indirectly, an employee, agent, client, customer, supplier, or contractor of either Party during the term of this Agreement and for a period of 2 years after termination of this Agreement.</p>
        <p><br></p>
        <p><strong>16.6. </strong> {{this.$global.companyrootnameshort}} is an independent contractor. Customer is responsible for its use of the Services. Each Party is responsible for determining the assignment of its and its affiliates&rsquo; personnel, and their respective contractors and vendors, and for their direction, control, and compensation.</p>
        <p><br></p>
        <p><strong>16.7. </strong> {{this.$global.companyrootnameshort}} may collect information relating to Customer&rsquo;s use of the Services. {{this.$global.companyrootnameshort}} may internally use this information for providing or improving the Services.</p>
        <p><br></p>
        <p><strong>16.8. </strong> Except as otherwise provided herein, Customer may not assign this Agreement, in whole or in part, without the prior written consent of {{this.$global.companyrootnameshort}}. Customer may assign this Agreement with 30 calendar days&rsquo; prior written notice to {{this.$global.companyrootnameshort}} upon a merger, acquisition, or purchase or sale of substantially all its assets so long as such transaction is not with a competitor of {{this.$global.companyrootnameshort}}. {{this.$global.companyrootnameshort}} may assign this Agreement at its sole discretion. Any assignment, transfer, or delegation of this Agreement in violation of this section is void.</p>
        <p><br></p>
        <p><strong>16.9. </strong> All notices and other communications under this Agreement must be in writing and sent to the business address specified in this Agreement or to such other address as shall be advised in writing. All notices and other communications under this Agreement shall be deemed to have been given on the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next operating business day if sent after normal business hours of the recipient or if mailed, on the third day after the date mailed, by certified or registered mail (in each case, return receipt requested, postage pre-paid).</p>
        <p><br></p>
        <p style="margin-left:20px"><strong>{{this.$global.companyrootname}}.</strong></p>
        <p style="margin-left:20px">Email: {{this.$global.userrootemail}}</p>
        <p style="margin-left:20px">Attention: {{this.$global.userrootname}}</p>
        <p style="margin-left:20px">Address: {{this.$global.userrootaddres}} {{this.$global.userrootcity}} {{this.$global.userrootstatecode}} {{this.$global.userrootzip}}</p>
        <p><br></p>
        <p style="margin-left:20px"><strong>{{CompanyName}}</strong></p>
        <p style="margin-left:20px">Email: {{CompanyEmail}}</p>
        <p style="margin-left:20px">Attention: {{CompanyCustomerCareName}}</p>
        <p style="margin-left:20px">Address: {{CompanyAddress}}</p>
        <p><br></p>
        <p><strong>16.10. </strong> This Agreement may be executed in two or more counterparts, all of which when taken together shall be considered one and the same agreement and become effective when counterparts have been signed by each Party and delivered to the other Party.</p>
        <p><br></p>
        <p><strong>16.11. </strong> The Parties agree that the electronic signatures of the Parties included in this Agreement are intended to authenticate this writing and to have the same force and effect as manual signatures. Electronic Signature means any electronic sound, symbol, or process attached to or logically associated with a record and executed and adopted by a party with the intent to sign such record, including clicking a button or checking a box next to the statement “I Agree”, pursuant to the Ohio Uniform Electronic Transactions Act (R.C. 1306.01 et seq.) as amended from time to time. The Parties consent to the use of electronic signatures and communication. Any reproduction of this Agreement made by reliable means is considered an original.</p>
        <p><br></p>
        <p><strong>16.12. </strong> This Agreement does not create any third-party rights. Neither Party will bring a legal action arising from, or relating to this Agreement, more than two years after the cause of action arose.</p>
        <p><br></p>
        <p><strong>16.13. </strong> Any terms that by their nature extend beyond this Agreement termination remain in effect until satisfied and apply to successors and assignees.</p>
        <p><br></p>
        <p><strong>16.14. </strong> Unless Customer requests otherwise in writing in advance, Customer authorizes {{this.$global.companyrootnameshort}} to use Customer&rsquo;s logo, name, or trademark on its website or other media as a customer.</p>
        <p><br></p>
        <p><strong>16.15. </strong> The Parties hereto are not responsible for failure to fulfill its obligations under this Agreement due to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may not be delayed under such causes beyond 15 calendar days.</p>
        <p><br></p>
        <p><strong>16.16. </strong> The Parties hereto are sophisticated, commercial Parties. This Agreement will not be construed against the drafter.</p>
        <p><br></p>
        <p><strong>16.17. </strong> Parties acknowledge that they have read this Agreement, understand it, and agree to be bound by its terms. The person signing on behalf of each Party is authorized to do so.</p>
        <p><br></p>
        <p class="bold"><a href="/privacy-policy/">EXHIBIT A</a></p>
        <p><br></p>
        <p class="bold"><a href="/term-of-use/">EXHIBIT B</a></p>
  </div>

  <div class="container" v-if="platformagency">
        <p class="bold text-center">Marketing Services Agreement</p>
        <p><br></p>
        <p><br></p>
        <p>This Marketing Agreement (“Marketing Agreement”) and applicable attachments or orders (“Order”) are the complete agreement regarding transactions under this agreement (together, the “Agreement”) under which Customer may order Services. Orders detail the specifics of transactions, such as charges and a description of the Services.</p>
        <p><br></p>
        <p class="bold">1. Services.</p>
        <p>1.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;In consideration for the payment of the subscription payments made in accordance with the applicable Order,&nbsp;{{CompanyName}}&nbsp;hereby provides the Services to Customer.</p>
        <p>1.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;Services&rdquo; means&nbsp;{{CompanyName}}&rsquo;s subscription services made available via the internet as described in an Order. Services include lead generation, marketing and advertising, data, data cleansing, documents, or other materials that&nbsp;{{CompanyName}}&nbsp;provides to Customer (&ldquo;Materials&rdquo;).</p>
        <p>1.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Services are available only to Authorized Users. &ldquo;Authorized User&rdquo; means employees (and any independent contractors performing functions comparable to employees in the ordinary course of business) of Customer and its affiliates who: (a) are authorized by&nbsp;{{CompanyName}}; (b) are bound by the Agreement; and (c) have their own subscription.</p>
        <p>1.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may access the Services only to the extent authorized by&nbsp;{{CompanyName}}. Customer is responsible for use of Services by its users, including its Authorized Users. Customer shall ensure that Authorized Users comply with&nbsp;{{CompanyName}}&rsquo;s <a :href="'' + CompanyTermsofuse" target="_blank">Terms of Use</a>)&nbsp;and  <a :href="'' + CompanyPrivacyPolicy" target="_blank">Privacy Policy</a>)&nbsp;which are incorporated herein by reference, when using the Services. Customer is responsible for its actions and the contents of its transmissions through the Services. Customer is responsible for the compliance of Customer Content with this Agreement, including content uploaded by its users.</p>
        <p>1.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may not: (a) duplicate, disassemble, reverse engineer, or otherwise reproduce without authorization any portion of the Services; (b) resell direct access to the Services to a third party; (c) scrape, steal, or copy without authorization Services; (d) disclose any performance data relating to the Services; (e) sell or transfer to another third party Services in violation of this Agreement; or (f) build a product, service, or offering that competes with&nbsp;{{CompanyName}}&nbsp;or Services.</p>
        <p>1.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may not use the Services for: (a) defamatory, harassing, abusive, threatening, obscene, hateful, sexist, offensive, or fraudulent content or activity; (b) activity that violates or infringes upon the rights of third parties; (c) activity that violates applicable law; (d) sending viruses, spyware or similar computer programming routines, or unsolicited mail; or (e) any purposes inconsistent with this Agreement or which violate the Terms of Use and Privacy Policy.</p>
        <p><br></p>
        <p class="bold">2. Customer Responsibilities.</p>
        <p>2.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Agreement and Services are a valuable trade secret and confidential proprietary property of&nbsp;{{CompanyName}}. Customer agrees to access and use&nbsp;{{CompanyName}}&rsquo;s Services only as provided in this Agreement and to safeguard&nbsp;{{CompanyName}}&rsquo;s trade secrets and confidential proprietary property. Customer agrees to delete the Materials upon termination of this Agreement, unless mutually agreed in writing.</p>
        <p>2.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer is hereby provided with limited access to the Services subject to the Agreement. Subject to the terms of the Agreement,&nbsp;{{CompanyName}}&nbsp;hereby provides to Customer a limited, non-exclusive, non-transferrable, revocable-at-any-time license during the term of the applicable Order to access and use the Materials for its business purposes. Except as otherwise stated in this section or the Order, Customer does not obtain any other rights to the Services or Materials. Customer&rsquo;s license to use the Materials is only valid for six (6) months from purchase (or less if required by&nbsp;{{CompanyName}}&nbsp;data providers, as set forth in more detail in the Order).</p>
        <p>2.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall (i) not use for solicitation the name, mailing address or telephone number of a consumer that is designated within the Service as requesting protection from solicitation, (ii) abide by all prevailing federal, state, and local laws and regulations governing fair information practices and consumers&rsquo; rights to privacy, and (iii) limit access to consumer information to those individuals who have a &ldquo;need to know&rdquo; in connection with Customer&rsquo;s business and will obligate those individuals to acknowledge consumers&rsquo; rights to privacy and adhere to fair information practices and consumer&rsquo;s right to privacy.</p>
        <p>2.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;Customer Content&rdquo; means all content or information that Customer provides or authorizes access to for the Services. Except as otherwise provided in the Order, Customer hereby grants&nbsp;{{CompanyName}}&nbsp;a perpetual, non-exclusive, royalty-free, transferrable, irrevocable to access, display, store, share, create derivative works of, transmit, or otherwise use or process de-identified Customer Content to provide or improve&nbsp;{{CompanyName}}&rsquo;s Services or for its business purposes. Customer warrants that it has the right and authority to provide Customer Content and that such materials do not infringe the rights of others or violate applicable law.</p>
        <p>2.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer is responsible for obtaining all necessary rights and permissions to enable, and grants such rights and permissions to,&nbsp;{{CompanyName}}, its affiliates, and their respective contractors and vendors to use, provide, store and otherwise process Customer Content in the Services. This includes Customer making necessary disclosures and obtaining consent, if required, before providing individuals&rsquo; information to&nbsp;{{CompanyName}}.</p>
        <p>2.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer is responsible for: (a) assessing the suitability of Services for Customer&rsquo;s intended use; (b) taking necessary actions to order, enable, or use available features appropriate for its use of the Services; and (c) complying with applicable law.</p>
        <p class="bold">3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Charges, Taxes, and Payment.</p>
        <p>3.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer agrees to pay all applicable charges specified for the Services and any charges imposed by any authority resulting from Customer&apos;s use of the Services, including any applicable sales taxes. Customer agrees to set up automatic payments in amounts due as set forth in this Agreement and any Order. To do so, Customer shall provide a valid credit card number or establish ACH funds transfer prior to obtaining access to the Services. Customer shall ensure adequate funds are available to pay any amounts due hereunder.</p>
        <p>3.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;All subscriptions are annual with monthly or annual billing payments. Except as otherwise stated in the Agreement, all subscription purchases are final and non-refundable. Annual subscription renewal cancellation requests are subject to the terms set forth in Section 10.2.</p>
        <p>3.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Amounts are due upon receipt of the invoice. Late payment fees may apply for amounts not paid within 30 calendar days of the invoice date.&nbsp;{{CompanyName}}&nbsp;may, at its sole discretion, terminate this Agreement if Customer has failed to pay any amount due for more than 30 calendar days.</p>
        <p><br></p>
        <p class="bold">4. Confidentiality.</p>
        <p>4.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&ldquo;Confidential Information&rdquo; means trade secrets, know-how, proprietary information, formulae, processes, techniques, and information concerning past, present, and future marketing, financial, research, and development activities that may be disclosed, orally or in writing, to each other. Confidential Information excludes information that was (a) previously known to the receiving party without an obligation of confidence; (b) independently developed by or for the receiving party without the use of Confidential Information; (c) lawfully acquired by the receiving party from a third party which is not under an obligation of confidence with respect to such information; or (d) or becomes publicly available through no fault of the receiving party without a breach of the Agreement.</p>
        <p>4.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall maintain the privacy, security, and confidentiality of Confidential Information and its access to the Services. A temporary password will be given upon account creation. Customer agrees to immediately reset their initial password with a strong password. Customer shall use strong and secure passwords and keep them secure and confidential. Customer shall promptly notify&nbsp;{{CompanyName}}&nbsp;in the event of a security breach or unauthorized use of their account. Customer is responsible for any damages incurred as a result of the unauthorized use of their account.</p>
        <p>4.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;{{CompanyName}}&nbsp;shall use reasonable efforts to maintain the privacy, security, and confidentiality of Confidential Information in accordance with the Agreement and its policies.</p>
        <p><br></p>
        <p class="bold">5. Warranties.</p>
        <p>5.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Each party warrants to the other that this Agreement been duly executed and delivered and constitutes a valid and binding agreement enforceable against such party in accordance with its terms.</p>
        <p><br></p>
        <p class="bold">6. Warranty Disclaimer.</p>
        <p>6.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;THE SERVICE IS PROVIDED &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. &nbsp;BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT APPLY. &nbsp;NEITHER&nbsp;{{CompanyName}}&nbsp;NOR ITS THIRD-PARTY PROVIDERS ASSURES&nbsp;OR ASSUMES ANY LIABILITY TO ANY PERSON OR ENTITY FOR THE PROPER PERFORMANCE OF SERVICES NECESSARY TO THE CONDUCT OF A REAL ESTATE CLOSING. &nbsp;NEITHER&nbsp;{{CompanyName}}&nbsp;NOR ITS THIRD-PARTY PROVIDER REPRESENT OR WARRANT (a) UNINTERRUPTED, TIMELY, OR ERROR-FREE SERVICES, (b) THAT&nbsp;{{CompanyName}}&nbsp;WILL CORRECT ANY DEFECTS OR PREVENT THIRD-PARTY DISRUPTIONS OR UNAUTHORIZED THIRD-PARTY ACCESS, OR (c) THAT SERVICES ARE SECURE, AVAILABLE, ACCURATE, PRIVATE, CONFIDENTIAL, APPROPRIATE, RELIABLE, OR COMPLETE. &nbsp;NEITHER&nbsp;{{CompanyName}}&nbsp;NOR ITS THIRD-PARTY PROVIDERS DO NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE, ACCIDENT, OR OTHER CAUSE. &nbsp;</p>
        <p><br></p>
        <p class="bold">7. Indemnity.</p>
        <p>7.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall defend, indemnify, and hold&nbsp;{{CompanyName}}, its affiliates, subsidiaries, their respective officers, directors, employees, agents, contractors, successors, and assigns harmless from and against any and all damages, losses, fines, penalties, costs, expenses, liabilities, and other fees (including, without limitation, reasonable legal fees) arising from or relating to any actual, alleged, or threatened claims, demands, investigations, or causes of action by third parties arising from or relating to the Agreement, including, without limitation, any claims relating to the Services.</p>
        <p>7.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If a third party asserts a claim against Customer that services offered by  {{CompanyName}} infringe a patent, copyright, or trademark,&nbsp;{{CompanyName}}&nbsp;will defend Customer against that claim and pay amounts finally awarded by a court against Customer or included in a settlement approved by&nbsp;{{CompanyName}}, provided that Customer promptly (a) notifies&nbsp;{{CompanyName}}&nbsp;in writing of the claim, (b) supplies information requested by&nbsp;{{CompanyName}}, and (c) allows&nbsp;{{CompanyName}}&nbsp;to control, and reasonably cooperates in, the defense, settlement, and mitigation.</p>
        <p>7.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&rsquo;s obligation to defend does not apply to any claim based on (a) Customer&rsquo;s combination of Services with data, software, or documentation not supplied, recommended, documented, or approved by&nbsp;{{CompanyName}}; or (b) Customer&rsquo;s unauthorized modifications to the Services.</p>
        <p>7.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Section 7 describes Customer&rsquo;s sole remedy against&nbsp;{{CompanyName}}&nbsp;relating to third-party claims of patent, copyright, or trademark infringement.</p>
        <p><br></p>
        <p class="bold">8. Limitation of Liability.</p>
        <p>8.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;WHERE PERMITTED BY APPLICABLE LAW, NEITHER PARTY IS LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, BUSINESS, REPUTATION, OPPORTUNITIES, VALUE, REVENUE, GOODWILL, OR ANTICIPATED SAVINGS; OR COST OF REPLACEMENT SERVICES. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER EITHER PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. WHERE PERMITTED BY APPLICABLE LAW,&nbsp;{{CompanyName}}&apos;S ENTIRE LIABILITY FOR ALL CLAIMS ARISING FROM OR RELATING TO THE AGREEMENT WILL NOT EXCEED 6 (SIX) MONTHS&rsquo; SERVICES FEES PAID BY CUSTOMER TO&nbsp;{{CompanyName}}, REGARDLESS OF THE BASIS OF THE CLAIM.</p>
        <p><br></p>
        <p class="bold">9. Privacy and Security.</p>
        <p>9.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;shall use commercially reasonable efforts comply with its Privacy Policy.</p>
        <p>9.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall&nbsp;comply&nbsp;with all applicable privacy laws and respect the privacy rights of individuals. Customer shall provide individuals with a privacy notice required for the processing of their personal data and use, maintain, and document appropriate legal purposes and means for processing personal data. Customer shall use adequate means to transfer personal data where required to do so and obtain the consent of individuals when using personal data in a manner inconsistent with the notice provided to them at the time of collection.</p>
        <p>9.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer shall allow individuals to exercise their rights under applicable law, including, without limitation, restricting processing, deleting, or opting out of sale of personal information; or opting out of receiving emails, calls, or other communications from it. Customer shall maintain exclusionary lists relating to individuals who do not wish to receive emails, calls, or other communications from it. Customer shall comply with applicable do not call and do not email lists.</p>
        <p><br></p>
        <p class="bold">10. Changes.</p>
        <p>10.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may, at&nbsp;{{CompanyName}}&apos;s reasonable discretion, modify the Services from time to time, with prior written notice where practicable, without any additional liability.&nbsp;{{CompanyName}}&rsquo;s modification to the Services will replace prior versions as of the effective date. Customer may not refuse these modifications to the Services. Notwithstanding the foregoing,&nbsp;{{CompanyName}}&nbsp;is not required to modify the Services.</p>
        <p>10.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may update its Terms of Use and Privacy Policy from time to time in accordance with their terms.</p>
        <p>10.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Except as otherwise provided, all changes to the Agreement must be in writing and signed by both parties.</p>
        <p><br></p>
        <p class="bold">11. Term and Termination.</p>
        <p>11.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The term of the SaaS Agreement begins upon execution and continues until terminated as described below. Termination of this SaaS Agreement by either party automatically terminates all Orders.</p>
        <p>11.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The term of Services is set forth in the Order. Services renew annually automatically unless Customer provides written notice to&nbsp;{{CompanyName}}&nbsp;not to renew at least 60 calendar days prior to the annual renewal.</p>
        <p>11.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may immediately, in its sole discretion and without prior written notice, temporarily suspend or limit Customer&apos;s use of the Services or the Agreement where&nbsp;{{CompanyName}}&nbsp;reasonably suspects a breach of the Agreement.&nbsp;{{CompanyName}}&nbsp;shall provide notice of the actions Customer must take to reinstate the Services.&nbsp;{{CompanyName}}&nbsp;may terminate the Services or this Agreement without any additional liability for Customer&rsquo;s failure to take required actions.</p>
        <p>11.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may terminate an Order or the Agreement without cause on at least 90 days&rsquo; written notice to the Customer.</p>
        <p>11.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Agreement shall automatically terminate upon the liquidation or insolvency of either party or the appointment of a trustee or receiver for either party.</p>
        <p>11.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Upon termination of the SaaS Agreement or applicable Order: (a)&nbsp;{{CompanyName}}&nbsp;may immediately terminate Customer&rsquo;s access to&nbsp;{{CompanyName}}&rsquo;s Services; and (b) Customer shall immediately cease using any portion of&nbsp;{{CompanyName}}&rsquo;s Services.</p>
        <p>11.7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer may not terminate the Agreement for convenience, except as otherwise stated in Section 10.2. Either party may terminate the Agreement for material breach upon 30 calendar days&rsquo; prior written notice, provided that the breaching party does not cure it within the 30-calendar day notice period. Customer&rsquo;s failure to timely pay&nbsp;{{CompanyName}}&nbsp;is a material breach.</p>
        <p>11.8.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If the Agreement is terminated for any reason (except for material breach by&nbsp;{{CompanyName}}), Customer shall pay to&nbsp;{{CompanyName}}, on the date of termination, the total amount outstanding under the Agreement. If&nbsp;{{CompanyName}}&nbsp;terminates for a reason other than Customer&rsquo;s material breach of the Agreement or if the Agreement is terminated for material breach by&nbsp;{{CompanyName}},&nbsp;{{CompanyName}}&nbsp;shall provide Customer a pro rata refund for any prepaid fees.</p>
        <p>11.9.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;After termination, Customer shall securely delete or destroy the Materials it has already incorporated into its databases or used for its business purposes. Upon request, Customer shall certify such deletion or destruction by its Chief Information Technology Officer, or equivalent, of Customer.&nbsp;{{CompanyName}}&nbsp;may audit Customer&rsquo;s compliance with these obligations and the license upon 5 days&rsquo; prior written notice.</p>
        <p>11.10.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;After termination, except as otherwise provided in an applicable Order,&nbsp;{{CompanyName}}&nbsp;may retain de-identified Customer Content incorporated into its Services, and Customer hereby grants&nbsp;{{CompanyName}}&nbsp;a perpetual, non-exclusive, royalty-free, transferrable, irrevocable license to access, display, store, share, create derivative works of, transmit, or otherwise use or process this Customer Content to provide or improve&nbsp;{{CompanyName}}&rsquo;s Services or for its business purposes.</p>
        <p><br></p>
        <p class="bold">12.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Governing Law.</p>
        <p>12.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement is governed by the laws of the State of Ohio, United States, without regard to conflict of law principles. The parties submit to the exclusive jurisdiction of, and venue in, the state or federal courts located in Franklin County, Ohio, in any action or proceeding arising from or relating to this Agreement. &nbsp;The United Nations Convention for the International Sale of Goods does not apply to this Agreement.</p>
        <p>12.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Either party may seek injunctive or other equitable relief for actual or threatened breach of confidentiality, security, or intellectual property protections hereunder by Customer under the Agreement.</p>
        <p>12.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Each party irrevocably waives, to the fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding arising from or relating to this Agreement.</p>
        <p><br></p>
        <p class="bold">13.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Audit. &nbsp;Customer hereby agrees that upon reasonable notice and at a mutually agreeable time,&nbsp;{{CompanyName}}&nbsp;may periodically audit Customer&rsquo;s books and records relevant to the use of this Service in order to ensure compliance with the terms hereof. &nbsp;The third-party data providers of&nbsp;{{CompanyName}}&nbsp;shall be deemed intended third-party beneficiaries of this provision and shall also be entitled investigate all legitimate reports of abuse or misuse of their Service. &nbsp;Violations discovered in any review will be subject to immediate action including, but not limited to, termination of Customer&rsquo;s right to use the Service, legal action, and/or referral to federal or state regulatory agencies.</p>
        <p class="bold">14.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;General.</p>
        <p>14.1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement constitutes the entire agreement of the parties and supersedes all prior or contemporaneous understandings, representations, discussions, or agreements between the parties relating to its subject matter.</p>
        <p>14.2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;In the event of a conflict between the Terms of Use and this Agreement, this Agreement governs. If there is a conflict between the Order and the Agreement, the Order governs.</p>
        <p>14.3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;If any provision of the Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.</p>
        <p>14.4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The waiver of a breach of any term of the Agreement, which must be in writing, will not operate as or be construed to be a waiver of any other previous or subsequent breach of the Agreement.</p>
        <p>14.5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer agrees not to hire or attempt to hire for employment, either directly or indirectly, an employee, agent, supplier, or contractor of&nbsp;{{CompanyName}}&nbsp;during the term of this Agreement and for a period of 2 years after termination of this Agreement.</p>
        <p>14.6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;is an independent contractor. Customer is responsible for its use of&nbsp;{{CompanyName}}&nbsp;Services. Each party is responsible for determining the assignment of its and its affiliates&rsquo; personnel, and their respective contractors and vendors, and for their direction, control, and compensation.</p>
        <p>14.7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{CompanyName}}&nbsp;may collect information relating to Customer&rsquo;s use of the Services.&nbsp;{{CompanyName}}&nbsp;may internally use this information for providing or improving the Services.</p>
        <p>14.8.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Except as otherwise provided herein, Customer may not assign the Agreement, in whole or in part, without the prior written consent of&nbsp;{{CompanyName}}. Customer may assign the Agreement with 30 calendar days&rsquo; prior written notice to&nbsp;{{CompanyName}}&nbsp;upon a merger, acquisition, or purchase or sale of substantially all its assets so long as such transaction is not with a competitor of&nbsp;{{CompanyName}}.&nbsp;{{CompanyName}}&nbsp;may assign the Agreement at its sole discretion. Any assignment, transfer, or delegation in violation of this section is void.</p>
        <p>14.9.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;All notices and other communications under this Agreement must be in writing and sent to the business address specified in this Agreement, unless a Party designates a different address in writing. All notices and other communications under this Agreement shall be deemed to have been given on the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next operating business day if sent after normal business hours of the recipient or if mailed, on the third day after the date mailed, by certified or registered mail (in each case, return receipt requested, postage pre-paid). Notices must be sent to the respective parties at the following addresses:</p>
        <p><strong>{{CompanyName}}</strong></p>
        <p>Email: {{CompanyEmail}}</p>
        <p>Attention: {{CompanyCustomerCareName}}</p>
        <p>Address: {{CompanyAddress}}</p>
        <p><strong>Customer:</strong> {{CustomerCompanyName}}</p>
        <p>Email: {{CustomerCompanyEmail}}</p>
        <p>Attention: {{CustomerName}}</p>
        <p>Address: {{CustomerAddress}}</p>
        <p>14.10.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement may be executed in two or more counterparts, all of which when taken together shall be considered one and the same agreement and become effective when counterparts have been signed by each party and delivered to the other party.</p>
        <p>14.11.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Parties agree that the electronic signatures of the Parties included in this Agreement are intended to authenticate this writing and to have the same force and effect as manual signatures. Electronic Signature means any electronic sound, symbol, or process attached to or logically associated with a record and executed and adopted by a party with the intent to sign such record, including clicking a button or checking a box next to the statement “I Agree”. The Parties consent to the use of electronic signatures and communication. Any reproduction of this Agreement made by reliable means is considered an original.</p>
        <p>14.12.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;This Agreement does not create any third-party rights. Neither party will bring a legal action arising from or relating to the Agreement more than two years after the cause of action arose.</p>
        <p>14.13.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Any terms that by their nature extend beyond the Agreement termination remain in effect until satisfied and apply to successors and assignees.</p>
        <p>14.14.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Unless Customer requests otherwise in writing in advance, Customer authorizes {{CompanyName}} to use Customer&rsquo;s logo, name, or trademark on its website or other media as a customer.</p>
        <p>14.15.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Neither party is not responsible for failure to fulfill its obligations under the Agreement due to causes beyond its control, except that Customer&rsquo;s payment obligations hereunder may not be delayed under such causes beyond 15 calendar days.</p>
        <p>14.16.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The parties hereto are sophisticated, commercial parties. The Agreement will not be construed against the drafter.</p>
        <p>14.17. &nbsp; &nbsp; &nbsp; &nbsp; Parties acknowledge that they have read the Agreement, understand it, and agree to be bound by its terms. The person signing on behalf of each party is authorized to do so.</p>
 </div>
</main>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            CompanyName: 'Exact Match Marketing',
            CompanyEmail: '',
            CompanyPhone: '',
            CompanyWeb: '',
            CompanyAddress: '',
            CompanyPrivacyPolicy: '',
            CompanyTermsofuse: '',
            CompanyCustomerCareName: '',
            CustomerCompanyName: '',
            CustomerCompanyEmail: '',
            CustomerName: '',
            CustomerAddress: '',
             
            platform: false,
            platformagency: false,
            emmplatform:false,
            domainurl: '',
        }
    },
    methods: {
       checkdomainsubdomain() {
            var user = this.$store.getters.userData
            var isClient = false;
            var _hostID = '';
            if (typeof(this.$route.query.host) != 'undefined' && this.$route.query.host != '' ) {
                _hostID = this.$route.query.host;
            }
            if (typeof(user.user_type) == 'undefined' || user.user_type == 'client') {
                isClient = true;
            }else if (typeof(this.$route.query.src) != 'undefined' && this.$route.query.src == 'client' ) {
                isClient = true;
            }
            
            var currurl = window.location.hostname
            const mainDomain = currurl.replace(/(.*?)\.(?=.*\.)/, '');

            if (localStorage.getItem('userDataOri') !== null && localStorage.getItem('rootcomp')) {
                if (localStorage.getItem('subdomainAgency')) {
                    currurl = localStorage.getItem('subdomainAgency');
                }
            }
            this.$store.dispatch('getDomainorSubInfo', {
                domainorsub: currurl,
                hostID : _hostID,
                }).then(response => {
                    //console.log(response);
                    //console.log(user);
                    this.CompanyName = response.params.companyname;
                    this.CompanyEmail = response.params.companyemail;
                    this.CompanyPhone = response.params.companyphone;
                    this.CompanyWeb = document.location.hostname;
                    this.CompanyAddress = response.params.company_address + ', ' + response.params.company_city + ', ' + response.params.company_state_name + ' ' + response.params.company_zip;
                    this.CompanyPrivacyPolicy = 'https://' + this.CompanyWeb + '/privacy-policy';
                    this.CompanyTermsofuse = 'https://' + this.CompanyWeb + '/term-of-use';
                    this.domainurl = (response.params.domain != "")?response.params.domain:response.params.subdomain;
                    this.CompanyCustomerCareName = response.params.customercarename;

                    if (typeof(this.$route.query.src) != 'undefined' && this.$route.query.src == 'client' ) {

                        this.CustomerCompanyName = response.params.companyrootname;
                        this.CustomerCompanyEmail = response.params.companyrootemail;
                        this.CustomerName = response.params.companyrootwwnername;
                        this.CustomerAddress = response.params.companyrootaddres + ', ' +  response.params.companyrootcity + ', ' +  response.params.companyrootstatename + ' ' +  response.params.companyrootzip;

                    }else{
                        this.CustomerCompanyName = user.company_name;
                        this.CustomerCompanyEmail = user.company_email,
                        this.CustomerName = user.name;
                        this.CustomerAddress = user.address + ', ' +  user.city + ', ' +  user.state_name + ' ' +  user.zip;
                    }
                    if (response.params.idsys != response.params.ownedcompanyid && isClient) {
                        this.platformagency = true;
                    }else{
                        if(user.user_type == 'userdownline' && response.params.agencyplatformroot == 'T') {
                            this.emmplatform = true;
                        }else{
                            this.platform = true;
                        }                        
                    }

                    this.$global.idsys = response.params.idsys;    
                    this.$global.companyrootname = response.params.companyrootname;
                    this.$global.companyrootlegalname = response.params.companyrootlegalname;
                    this.$global.companyrootnameshort = response.params.companyrootnameshort;
                    this.$global.companyrootaddres = response.params.companyrootaddres;
                    this.$global.companyrootcity = response.params.companyrootcity;
                    this.$global.companyrootzip = response.params.companyrootzip;
                    this.$global.companyrootstatecode = response.params.companyrootstatecode;
                    this.$global.companyrootstatename = response.params.companyrootstatename;
                    this.$global.companyrootdomain = response.params.companyrootdomain;
                    this.$global.companyrootsubdomain = response.params.companyrootsubdomain;
                    
                    this.$global.userrootname = response.params.userrootname;
                    this.$global.userrootemail = response.params.userrootemail;
                    this.$global.userrootaddres = response.params.userrootaddres;
                    this.$global.userrootcity = response.params.userrootcity;
                    this.$global.userrootzip = response.params.userrootzip;
                    this.$global.userrootstatecode = response.params.userrootstatecode;
                    this.$global.userrootstatename = response.params.userrootstatename;

                },error => {
                    // this.parentCompanyInfo.pathlogo = "/img/EMMLogo.png";
                    // alert('Your domain or subdomain not register yet');
                    document.location = 'https://' + mainDomain;
                })
            },
    },
    mounted() {
        this.checkdomainsubdomain();
    },
}
</script>
<style>
.servicebillingagreement-page {
    background-color: white;
    padding-top: 40px;
    /*margin-top: -80px;*/
}

body {
    background-color: white;
}

#servicebilling p {
    text-align: justify;
    font-size:12pt;
    font-family:'Times New Roman';
    color:#000;
    background-color:transparent;
    font-weight:normal;
    font-style:normal;
    font-variant:normal;
    text-decoration:none;
    vertical-align:baseline;
    white-space:pre;
    white-space:pre-wrap;
}

#servicebilling p.bold {
    font-weight: bold;
}

#servicebilling ul {
    list-style-type: disc;
}

#servicebilling ul li, ol li {
    color: #000;
}

#servicebilling h1, h2 {
    color: #000;
}
</style>